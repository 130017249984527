import Button from 'components/Button'
import Header from 'components/Header'
import { CheckboxGroup, InputItem } from 'components/input'
import InputText, { InputTextProps } from 'components/InputText'
import Modal from 'components/modal'
import validate, { emailValue, requiredValue, useValidation } from 'components/validation'
import useBoolean from 'hooks/useBoolean'
import React, { FC, FormEvent, useState } from 'react'
import { ContactExpertData } from 'store'

import css from './contact-expert.module.scss'

const validations = [
  requiredValue('needs', 'API Needs'),
  requiredValue('firstName'),
  requiredValue('lastName'),
  emailValue('email'),
]

const needItems: InputItem[] = [
  { value: 'Address Verification' },
  { value: 'Property Comps' },
  { value: 'Address Autocomplete' },
  { value: 'Property Owner Lookup' },
  { value: 'Property Search' },
  { value: 'API Skip Tracing' },
  { value: 'Property Lookup' },
  { value: 'Market Trends' },
]

const ContactExpert: FC<{
  close: () => void
}> = ({
  close,
}) => {
  const [complete, setComplete] = useState(false)

  const [loading, setLoading] = useState(false)

  const [data, setData] = useState<ContactExpertData>({})

  const context = useValidation(validations)

  const inputProps: Partial<InputTextProps> = {
    onChange: ({ changes }) => setData({ ...data, ...changes }),
    validationContext: context,
    value: data,
    readOnly: complete,
  }

  const handleSubmit = (ev: FormEvent): void => {
    ev.preventDefault()

    if (validate(context, data)) {
      setLoading(true)

      fetch('/api/contact-expert', {
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
      }).then(() => {
        setComplete(true)
      }).finally(() => setLoading(false))
    }
  }

  return (
    <Modal
      close={close}
      okLabel="Close"
      onSubmit={complete ? close : handleSubmit}
    >
      <div className={css.frame}>
        <div className={css.form}>
          <Header value="Let's Get Started" />
          <div className={css.subHeader}>We want to know how we can better get you what<br />you need to succeed.</div>
          <div className={css.fields}>
            <div className={css.name}>
              <InputText {...inputProps} name="firstName" placeholder="First Name" autoFocus />
              <InputText {...inputProps} name="lastName" placeholder="Last Name" />
            </div>
            <InputText {...inputProps} name="email" placeholder="Email Address" />
            <InputText {...inputProps} name="companyName" placeholder="Company Name" />
          </div>
          <div className={css.label}>What are your API Needs?</div>
          <CheckboxGroup
            className={css.needs}
            items={needItems}
            name="needs"
            onChange={ev => setData({ ...data, needs: ev.value })}
            value={data.needs}
          />
          {complete ? (
            <>
              <div className={css.confirmation}>We have received your submission and will respond within 3-5 business days.</div>
              <Button solid className={css.button} onClick={close}>Close</Button>
            </>
          ) : (
            <Button solid submit className={css.button} loading={loading}>Talk To An Expert</Button>
          )}
        </div>
      </div>
    </Modal>
  )
}

export const useContactExpert = () => {
  const [opened, open, close] = useBoolean(false)

  return {
    openContactExpert: open,
    contactExpertElement: opened ? <ContactExpert close={close} /> : null,
  }
}

export default ContactExpert
