import { createInputChangeEvent } from 'components/input/index'
import {
  InputChangeEventHandler,
  InputItem, TextInputDef, TextInputConverter,
  TextInputConverterOptions,
  TextInputFormatter, TextInputParser,
  TextInputType
} from 'components/input/types'
import dayjs from 'dayjs'
import { useMemo } from 'react'
import { parseMMDDYYYY } from 'utils/date'
import { round } from 'utils/math'

export const updateMultiValue = (
  onChange: InputChangeEventHandler,
  name: string | undefined,
  items: InputItem[],
  currentValue: any,
  toggledValue: any,
  selected: boolean,
): void => {
  const toggledValues = Array.isArray(toggledValue) ? toggledValue : [toggledValue]

  const newValue = items.filter(({ value, disabled = false }) =>
    !disabled && (
      toggledValues.includes(value) && selected   // Toggled set of items' new status indicated by selected state
      || (!toggledValues.includes(value) && Array.isArray(currentValue) && currentValue.includes(value))  // Items outside of toggled set retain their current selected state
    )
  ).map(i => i.value)

  onChange(createInputChangeEvent(name, newValue))
}

export const getMultiValueList = (items: InputItem[], value: any, sep = ', ') => {
  // Pull label from associated item; default to value if none (i.e. the label = value).
  const values = Array.isArray(value) ? value : [value]

  return items.filter(i => values.includes(i.value)).map(i => i.label ?? i.value).join(sep)
}

export const useInputValue = (inputValue: any, name?: string): { value: any, item: object } => {
  // Input value may be passed directly as "value", or may be a member of an item value references.
  const item = typeof inputValue === 'object' && !Array.isArray(inputValue) ? inputValue : undefined
  const value = useMemo(() => item != null && name != null ? item[name] : inputValue, [item, name, inputValue])

  return { item, value }
}

export const numberFormatter: TextInputFormatter = (value: any, { coefficient = 1 }: TextInputConverterOptions = {}): string => {
  if (value == null) return ''

  return String(typeof value === 'number' ? value * coefficient : value)
}

export const currencyParser: TextInputParser = value => numberParser(value, { minValue: 0, wholeNumber: true })

export const decimalParser: TextInputParser = value => numberParser(value, { minValue: 0 })

export const numberConverter: TextInputConverter = { formatter: numberFormatter, parser: currencyParser }

export const decimalConverter: TextInputConverter = { formatter: numberFormatter, parser: decimalParser }

export const textConverter: TextInputConverter = {
  formatter: value => value === undefined ? '' : String(value),
  parser: value => value,
}

export const numberParser = (value: any, options: TextInputConverterOptions = {}): number | undefined => {
  const {
    coefficient = 1,
    minValue,
    maxValue = 2147483647,
    wholeNumber = false,
  } = options

  // Resolve numeric value; allow for commas and strip them out
  let num = value == null || value === '' ? null :
    Number(typeof value === 'string' ? value.replaceAll(/,/g, '') : value)

  // Return undefined if value is not a number or doesn't fall within constraints
  if (num == null || isNaN(num) || num < (minValue ?? num) || num > (maxValue ?? num)) return undefined

  num *= coefficient

  // If coefficient is a whole number, then ensure result is rounded
  if (round(coefficient) === coefficient && wholeNumber) num = round(num)

  return num
}

export const textInputDefs: Record<TextInputType, TextInputDef> = {
  currency: {
    converter: numberConverter,
    fieldType: 'number',
    maxLength: 13,
    thousandSeparator: true,
  },
  "currency-full": {
    converter: decimalConverter,
    fieldType: 'number',
    fixedDecimalScale: true,
    maxLength: 13,
    decimalScale: 2,
    thousandSeparator: true,
  },
  date: {
    fieldType: 'text',
    converter: {
      formatter: value => value === undefined ? '' : dayjs(value).format('M/D/YYYY'),
      parser: value => {
        const date = parseMMDDYYYY(value)
        return date === undefined ? undefined : date.format('YYYY-MM-DD')
      },
    },
  },
  decimal: {
    converter: decimalConverter,
    decimalScale: 5,
    fieldType: 'number',
    maxLength: 13,
    thousandSeparator: true,
  },
  int: {
    converter: numberConverter,
    fieldType: 'number',
    maxLength: 13,
    thousandSeparator: true,
  },
  short: {
    converter: { formatter: numberFormatter, parser: value => numberParser(value, { minValue: 0, maxValue: 32767 }) },
    fieldType: 'number',
    maxLength: 6,
    thousandSeparator: true,
  },
  year: {
    converter: { formatter: numberFormatter, parser: value => numberParser(value, { minValue: 1800, maxValue: dayjs().year() + 50 }) },
    fieldType: 'number',
    maxLength: 4,
  },
  zip: {
    converter: textConverter,
    fieldType: 'text',
    maxLength: 5,
  },
  cc: {
    converter: { formatter: numberFormatter, parser: value => value },
    fieldType: 'number',
    maxLength: 16,
  },
  cvv: {
    converter: textConverter,
    fieldType: 'text',
    maxLength: 4,
  },
  percent: {
    allowNegative: true,
    converter: {
      formatter: value => numberFormatter(value, { coefficient: 100 }),
      parser: value => numberParser(value, { coefficient: 0.01 }),
    },
    decimalScale: 3,
    fieldType: 'number',
    maxLength: 13,
    thousandSeparator: true,
  },
  password: {
    converter: textConverter,
    fieldType: 'password',
  },
  string: {
    converter: textConverter,
    fieldType: 'text',
  },
}

export const offOnItems: InputItem[] = [
  { label: 'Off', value: false },
  { label: 'On', value: true },
]

export const pastIntervalItems: InputItem[] = [
  { label: 'Any' },
  { label: 'Within last 12 months', value: 12 },
  { label: 'Within last 9 months', value: 9 },
  { label: 'Within last 6 months', value: 6 },
  { label: 'Within last 3 months', value: 3 },
  { label: 'Within last 1 month', value: 1 },
]

export const landUseItems: InputItem[] = [
  { value: 1001, label: 'Single Family (SFR)' },
  { value: 1004, label: 'Condominium' },
  { value: 1002, label: 'Townhouse' },
  { value: 1013, label: 'Bungalow' },
  { value: 1012, label: 'Cabin/Vacation Residence' },
  { value: 1003, label: 'Cluster Home' },
  { value: 1005, label: 'Cooperative' },
  { value: 1018, label: 'Garden Home' },
  { value: 1019, label: 'Landominium' },
  { value: 1006, label: 'Manufactured Homes' },
  { value: 1015, label: 'Misc Res Improvement' },
  { value: 1017, label: 'Patio Home' },
  { value: 1009, label: 'Planned Unit Development' },
  { value: 1010, label: 'Residential Common Area' },
  { value: 1000, label: 'Residential General' },
  { value: 1007, label: 'Row House' },
  { value: 1008, label: 'Rural Residence' },
  { value: 1011, label: 'Timeshare' },
  { value: 1020, label: 'Barndominium' },
  { value: 1014, label: 'Zero Lot Line' },
  { value: 1021, label: 'Unknown' },
  { value: 1101, label: 'Duplex 2 Units' },
  { value: 1102, label: 'Triplex 3 Units' },
  { value: 1103, label: 'Quadruplex 4 Units' },
  { value: 1112, label: 'Apartments' },
  { value: 1100, label: 'Multi-Family General' },
  { value: 1110, label: 'Multi-Family 2+' },
  { value: 1104, label: 'Apt House 5+ Units' },
  { value: 1105, label: 'Apt House 100+ Units' },
  { value: 1106, label: 'Garden Apt 5+ Units' },
  { value: 1108, label: 'Boarding House/Apt Hotel' },
  { value: 1113, label: 'Dormitory/Group Quarters' },
  { value: 1111, label: 'Fraternity/Sorority House' },
  { value: 1107, label: 'High-rise Apartments' },
  { value: 1109, label: 'Mobile Home/Trailer Park' },
  { value: 1901, label: 'Parking Garage Residential' },
  { value: 1124, label: 'Residential Condo Dev' },
  { value: 1902, label: 'Storage Space Residential' },
  { value: 3000, label: 'Commercial Office' },
  { value: 3011, label: 'Common Area' },
  { value: 3008, label: 'Condominium Offices' },
  { value: 3005, label: 'Dental Bldg' },
  { value: 3007, label: 'Financial Bldg' },
  { value: 3006, label: 'Medical Bldg' },
  { value: 3012, label: 'Mobile Commercial Units' },
  { value: 3003, label: 'Office Bldg (General)' },
  { value: 3004, label: 'Office Bldg (multi-story)' },
  { value: 3001, label: 'Professional Bldg' },
  { value: 3002, label: 'Professional Bldg 2+ Story' },
  { value: 3009, label: 'Skyscraper/Highrise (Commercial Offices)' },
  { value: 2011, label: 'Animal Hospital/Vet' },
  { value: 2045, label: 'Appliance Store' },
  { value: 2024, label: 'Auto repair/parts/garage' },
  { value: 2015, label: 'Bakery' },
  { value: 2016, label: 'Bar, Tavern' },
  { value: 2055, label: 'Barber/Hair Salon' },
  { value: 2054, label: 'Bed & Breakfast' },
  { value: 2060, label: 'Cannabis Dispensary' },
  { value: 2025, label: 'Car wash' },
  { value: 2038, label: 'Casino' },
  { value: 2043, label: 'Commercial Building' },
  { value: 2052, label: 'Commercial Condominium' },
  { value: 9106, label: 'Convalescent/Nursing Home' },
  { value: 2018, label: 'Convenience store ' },
  { value: 2019, label: 'Convenience Store w/ Fuel' },
  { value: 2032, label: 'Day Care, Pre-School' },
  { value: 2004, label: 'Department Store' },
  { value: 2005, label: 'Department Store 2+ Story' },
  { value: 2013, label: 'Drive-thru Restaurant' },
  { value: 2053, label: 'Drug Store or Pharmacy' },
  { value: 2026, label: 'Dry Cleaner, Laundry' },
  { value: 2050, label: 'Farm Supply & Equipment' },
  { value: 2037, label: 'Funeral Home, Mortuary' },
  { value: 2051, label: 'Garden Center, Home Imp' },
  { value: 2041, label: 'Gas Station' },
  { value: 2000, label: 'General' },
  { value: 2006, label: 'Grocery, Supermarket' },
  { value: 2034, label: 'Hotel' },
  { value: 2040, label: 'Hotel or Motel' },
  { value: 2039, label: 'Hotel-Resort' },
  { value: 2046, label: 'Kennel' },
  { value: 2047, label: 'Laundromat (self-service)' },
  { value: 2017, label: 'Liquor Store' },
  { value: 2031, label: 'Mini-Warehouse, Storage' },
  { value: 2042, label: 'Mixed Use' },
  { value: 2033, label: 'Motel' },
  { value: 2048, label: 'Nightclub' },
  { value: 2028, label: 'Nursery/Florist' },
  { value: 2035, label: 'Parking Garage/Structure' },
  { value: 2036, label: 'Parking Lot' },
  { value: 2056, label: 'Pet Grooming' },
  { value: 2030, label: 'Printer - Retail' },
  { value: 2012, label: 'Restaurant' },
  { value: 2001, label: 'Retail Stores' },
  { value: 2057, label: 'Roadside Market' },
  { value: 2027, label: 'Service Shop' },
  { value: 2020, label: 'Service Station' },
  { value: 2021, label: 'Service Station w/ Food' },
  { value: 2010, label: 'Shopping Center Common Area' },
  { value: 2007, label: 'Shopping Center/Mall' },
  { value: 2008, label: 'Shopping Center/Mini-Mall' },
  { value: 2002, label: 'Store (multi-story)' },
  { value: 2003, label: 'Store/Office (mixed use)' },
  { value: 2014, label: 'Take-out Restaurant' },
  { value: 2022, label: 'Truck Stop' },
  { value: 2023, label: 'Vehicle Rentals Sales' },
  { value: 2029, label: 'Wholesale Outlet/Discount' },
  { value: 8008, label: 'Agricultural Vacant Land' },
  { value: 8002, label: 'Commercial Vacant Land' },
  { value: 8003, label: 'Industrial Vacant Land' },
  { value: 8005, label: 'Institutional Vacant Land' },
  { value: 8001, label: 'Residential Vacant Land' },
  { value: 8013, label: 'Abandoned/Contaminated' },
  { value: 8504, label: 'Centrally Assessed' },
  { value: 714, label: 'Forest' },
  { value: 8006, label: 'Government Vacant Land' },
  { value: 8011, label: 'Lake/River/Shore' },
  { value: 8007, label: 'Multi-Family Vacant Land' },
  { value: 8004, label: 'Private/Preserve' },
  { value: 8010, label: 'Recreational Vacant Land' },
  { value: 8503, label: 'Redevelopment Zone' },
  { value: 8501, label: 'SBE - Special Assessments' },
  { value: 8500, label: 'Special Purpose' },
  { value: 8502, label: 'Tax Abatement' },
  { value: 8014, label: 'Under Construction' },
  { value: 8015, label: 'Unknown' },
  { value: 8012, label: 'Unusable Land' },
  { value: 8000, label: 'Vacant Land General' },
  { value: 8009, label: 'Waste Land, Marsh, Swamp' },
  { value: 7000, label: 'Agricultural / Rural' },
  { value: 7024, label: 'Cannabis Grow Facility' },
  { value: 7004, label: 'Crops' },
  { value: 7008, label: 'Dairy Farm' },
  { value: 7011, label: 'Desert or Barren Land' },
  { value: 7001, label: 'Farm land ' },
  { value: 7015, label: 'Feedlots' },
  { value: 7014, label: 'Groves' },
  { value: 7017, label: 'Horticulture, Growing Houses' },
  { value: 7021, label: 'Irrigation, Flood Control' },
  { value: 7016, label: 'Livestock' },
  { value: 7013, label: 'Misc. Structures' },
  { value: 7022, label: 'Natural Resources' },
  { value: 7005, label: 'Orchard (fruit; nut)' },
  { value: 7012, label: 'Pasture, Meadow' },
  { value: 7007, label: 'Poultry Farm' },
  { value: 7002, label: 'Ranch' },
  { value: 7003, label: 'Range Land' },
  { value: 7020, label: 'Reservoir, Water Supply' },
  { value: 7023, label: 'Rural Improved' },
  { value: 7009, label: 'Timberland, Forest, Trees' },
  { value: 7019, label: 'Truck Crops' },
  { value: 7006, label: 'Vineyard' },
  { value: 7018, label: 'Well Site' },
  { value: 7010, label: 'Wildlife Refuge' },
  { value: 5006, label: 'Assembly' },
  { value: 6020, label: 'Cannery' },
  { value: 6018, label: 'Chemical' },
  { value: 6024, label: 'Cold Storage' },
  { value: 5020, label: 'Common Area' },
  { value: 5009, label: 'Communications' },
  { value: 5010, label: 'Condominiums' },
  { value: 5019, label: 'Construction Services' },
  { value: 6005, label: 'Distillery/Brewery' },
  { value: 6002, label: 'Distribution Warehouse' },
  { value: 6023, label: 'Dump Site' },
  { value: 6008, label: 'Factory (medium)' },
  { value: 5005, label: 'Food Packing, Packing Plant' },
  { value: 5007, label: 'Food Processing ' },
  { value: 6019, label: 'Foundry, Industrial Plant' },
  { value: 6021, label: 'Gasoline/Fuel Storage' },
  { value: 6022, label: 'Grain Elevator' },
  { value: 6000, label: 'Heavy Industrial' },
  { value: 6015, label: 'Heavy Manufacturing' },
  { value: 5000, label: 'Industrial (General)' },
  { value: 5018, label: 'Industrial Loft Building' },
  { value: 5012, label: 'Industrial Park' },
  { value: 5011, label: 'Lab/R&D Facility' },
  { value: 6016, label: 'Labor Camps (Industrial)' },
  { value: 5002, label: 'Light Industrial' },
  { value: 5015, label: 'Lumber & Wood Product MFG' },
  { value: 6010, label: 'Lumberyard/Bldg Materials' },
  { value: 5001, label: 'Manufacturing (light)' },
  { value: 5014, label: 'Marine Facil/Boat Repair' },
  { value: 6007, label: 'Mill' },
  { value: 6003, label: 'Mining' },
  { value: 5013, label: 'Multi-Tenant Bldg' },
  { value: 5016, label: 'Paper Product MFG' },
  { value: 5017, label: 'Printing & Publishing' },
  { value: 6009, label: 'Processing Plant' },
  { value: 6014, label: 'Quarries' },
  { value: 5008, label: 'Recycling plant' },
  { value: 6006, label: 'Refinery/Crude Oil Product' },
  { value: 6011, label: 'Shipyard/Storage' },
  { value: 6012, label: 'Slaughter House/Stockyard' },
  { value: 6004, label: 'Storage Yard, Junk/Salvage' },
  { value: 5004, label: 'Storage Yard/Open Storage' },
  { value: 6025, label: 'Sugar Refinery' },
  { value: 6001, label: 'Transportation' },
  { value: 5003, label: 'Warehouse' },
  { value: 6013, label: 'Waste/Treatment bldg' },
  { value: 6017, label: 'Winery' },
  { value: 4022, label: 'Amusement Park' },
  { value: 4005, label: 'Arcades' },
  { value: 4011, label: 'Arenas/Convention Center' },
  { value: 4012, label: 'Auditoriums' },
  { value: 4003, label: 'Boat Marina/Yacht Club' },
  { value: 4004, label: 'Bowling Alley' },
  { value: 4018, label: 'Campground, RV Park' },
  { value: 4007, label: 'Clubs/Lodges Prof. Assoc.' },
  { value: 4009, label: 'Country Club' },
  { value: 4016, label: 'Dance Hall' },
  { value: 4021, label: 'Drive-In Theater' },
  { value: 4019, label: 'Fairgrounds' },
  { value: 4024, label: 'Fish Camps/Game Club' },
  { value: 4031, label: 'Go-Carts/Mini Golf/Slides' },
  { value: 4028, label: 'Golf Course' },
  { value: 4013, label: 'Golf Driving Range' },
  { value: 4015, label: 'Gym, Health Spa' },
  { value: 4006, label: 'Ice/Roller Skating Rink' },
  { value: 4008, label: 'Museums/Library' },
  { value: 4025, label: 'Outdoor Rec. Beach/Desert' },
  { value: 752, label: 'Park/Playground/Picnic' },
  { value: 4023, label: 'Piers, Wharf ' },
  { value: 4026, label: 'Pool Hall/Billiard Parlor' },
  { value: 4002, label: 'Public Swimming Pool' },
  { value: 4014, label: 'Race track ' },
  { value: 4029, label: 'Racquet or Tennis Court' },
  { value: 4001, label: 'Recreation Center' },
  { value: 4000, label: 'Recreation/Entertainment' },
  { value: 4017, label: 'Riding Stable, Trails' },
  { value: 4032, label: 'Sports Complex' },
  { value: 4010, label: 'Stadiums' },
  { value: 4020, label: 'Theater' },
  { value: 4030, label: 'Zoo' },
  { value: 6501, label: 'Airport & related' },
  { value: 6512, label: 'Auto Transport/Storage' },
  { value: 6505, label: 'Bus Terminal' },
  { value: 6509, label: 'Cable TV Station Comm' },
  { value: 6503, label: 'Freeways, State Hwys' },
  { value: 6510, label: 'Harbor/Marine Transport' },
  { value: 6511, label: 'Microwave' },
  { value: 6513, label: 'Pollution Control' },
  { value: 6507, label: 'Radio/TV Station Comm' },
  { value: 6502, label: 'Railroad & related' },
  { value: 6504, label: 'Roads, Streets, Bridges' },
  { value: 6506, label: 'Telephone Comm' },
  { value: 6500, label: 'Transportation/Comm' },
  { value: 6508, label: 'Truck Terminal' },
  { value: 9108, label: 'Cemetery' },
  { value: 9110, label: 'Charitable organization' },
  { value: 9107, label: 'Children\'s Home/Orphanage' },
  { value: 9210, label: 'City/Municipal Owned' },
  { value: 9204, label: 'College/University-PUBLIC' },
  { value: 9215, label: 'Community Center' },
  { value: 9211, label: 'County Owned' },
  { value: 9109, label: 'Crematorium/Mortuary' },
  { value: 9206, label: 'Cultural, Historical' },
  { value: 9103, label: 'Educational - PRIVATE' },
  { value: 9208, label: 'Emergency Police/Fire/etc' },
  { value: 9000, label: 'Exempt (full or partial)' },
  { value: 9213, label: 'Federal Property' },
  { value: 9202, label: 'Forest Park/Reserve' },
  { value: 9200, label: 'Government/Public Use' },
  { value: 9207, label: 'Govt. Admin Office' },
  { value: 9309, label: 'Historical District' },
  { value: 9305, label: 'Historical Hotel/Motel' },
  { value: 9304, label: 'Historical Office' },
  { value: 9308, label: 'Historical Park/Site/Misc' },
  { value: 9307, label: 'Historical Rec./Ent.' },
  { value: 9301, label: 'Historical Residence' },
  { value: 9302, label: 'Historical Retail' },
  { value: 9303, label: 'Historical Warehouse' },
  { value: 9300, label: 'Historical-PRIVATE' },
  { value: 9104, label: 'Hospital-PRIVATE' },
  { value: 9219, label: 'Hospital-PUBLIC' },
  { value: 9001, label: 'Indian Lands' },
  { value: 9100, label: 'Institutional' },
  { value: 9218, label: 'Jail/Prison/Asylum' },
  { value: 9105, label: 'Medical Clinic' },
  { value: 9201, label: 'Military' },
  { value: 9209, label: 'Other exempt property' },
  { value: 9102, label: 'Parochial/Private School' },
  { value: 9205, label: 'Post Office' },
  { value: 9112, label: 'Private Utility' },
  { value: 9203, label: 'Public Colleges/University' },
  { value: 9214, label: 'Public Health Care Bldg' },
  { value: 9216, label: 'Public Utility' },
  { value: 9111, label: 'Recreational Non-Taxable' },
  { value: 9101, label: 'Religious/Church/Worship' },
  { value: 9212, label: 'State Owned' },
  { value: 8017, label: 'Vacant Land' },
  { value: 9217, label: 'Welfare/Low Inc Housing' },
  { value: 514, label: 'Aircraft' },
  { value: 529, label: 'Misc Business' },
  { value: 6514, label: 'Cellular' },
  { value: 520, label: 'Business Personal Prop' },
  { value: 24, label: 'Common Area' },
  { value: 526, label: 'Crops (harvested)' },
  { value: 525, label: 'Crops (in ground)' },
  { value: 22, label: 'Easement' },
  { value: 521, label: 'Equipment / Supplies' },
  { value: 523, label: 'Goods in transit' },
  { value: 23, label: 'Homestead' },
  { value: 540, label: 'Intangible personal prop' },
  { value: 522, label: 'Inventory' },
  { value: 17, label: 'Leasehold Rights' },
  { value: 524, label: 'Livestock' },
  { value: 519, label: 'Misc Vehicles' },
  { value: 10, label: 'Miscellaneous' },
  { value: 511, label: 'Motor Vehicles' },
  { value: 500, label: 'Personal Property General' },
  { value: 19, label: 'Petroleum/Gas Wells' },
  { value: 11, label: 'Pipeline or Right-of-Way' },
  { value: 18, label: 'Possessory Interest' },
  { value: 599, label: 'Property Not Classed' },
  { value: 21, label: 'Right-of-Way Other' },
  { value: 12, label: 'Right-of-Way Rail' },
  { value: 13, label: 'Right-of-Way Road' },
  { value: 14, label: 'Right-of-Way Utilities' },
  { value: 515, label: 'Rolling stock (railroad)' },
  { value: 25, label: 'Royalty Interest' },
  { value: 516, label: 'SpaceCraft' },
  { value: 530, label: 'Structures (general)' },
  { value: 539, label: 'Structures Not Classed' },
  { value: 532, label: 'Structures on leased land' },
  { value: 15, label: 'Surface Rights Mineral' },
  { value: 16, label: 'Surface Rights Timber' },
  { value: 533, label: 'Temporary Structures' },
  { value: 512, label: 'Travel Trailers' },
  { value: 27, label: 'Vacant parcels w/ imp.' },
  { value: 510, label: 'Vehicles General' },
  { value: 20, label: 'Water Rights (misc.)' },
  { value: 513, label: 'Watercraft' },
  { value: 26, label: 'Working Interest' },
  { value: 0, label: 'Unknown' },
]
