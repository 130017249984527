import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

const useRouteChange = (onStart?: () => void, onComplete?: () => void): { changing: boolean } => {
  const { events } = useRouter()
  const [changing, setChanging] = useState(false)

  useEffect(() => {
    const handleStart = () => {
      setChanging(true)
      if (onStart != null) onStart()
    }

    const handleComplete = () => {
      setChanging(false)
      if (onComplete != null) onComplete()
    }

    events.on('routeChangeStart', handleStart)
    events.on('routeChangeComplete', handleComplete)

    return () => {
      events.off('routeChangeStart', handleStart)
      events.off('routeChangeComplete', handleComplete)
    }
  }, [])

  return { changing }
}

export default useRouteChange
