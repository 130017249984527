import { useState } from 'react'

const useBoolean = (defaultValue: boolean): [
  boolean,
  () => void,
  () => void,
  (value: boolean) => void
] => {
  const [value, setValue] = useState(defaultValue)

  return [
    value,
    () => setValue(true),
    () => setValue(false),
    (value: boolean) => setValue(value),
  ]
}

export default useBoolean
