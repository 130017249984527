import classNames from 'classnames'
import Svg from 'components/Svg'
import React, { FC } from 'react'
import css from './header.module.scss'


const renderValue = (val: string | string[]): React.ReactNode => {
  return (typeof val === 'string' ? [val] : val).map((s, i) => (
    <span
      key={s}
      className={classNames({
        [css.highlight]: s.startsWith('*'), // Text to be highlighted with primary color will be "*"-prefixed
        [css.dashBreak]: s === '-', // A solo dash should be a dash separator on desktop, and a break on mobile
      })}
    >
      {`${i === 0 ? '' : ' '}${s.replace(/^[*-]/g, '')}`}
    </span>
  ))
}

const Header: FC<{
  bannerMode?: boolean
  centered?: boolean
  children?: React.ReactNode
  primary?: boolean
  className?: string
  subHeader?: string | string[]
  value: string | string[]
}> = ({
  bannerMode = false,
  centered = false,
  children,
  primary,
  className,
  subHeader,
  value
}) => {
  // Use H1 tag if primary is true, or undefined AND in banner mode
  const primaryMode = primary ?? bannerMode

  const Tag = primaryMode ? 'h1' : 'h2'
  const SubHeaderTag = primaryMode ? 'h2' : 'h3'

  return (
    <div
      className={classNames(css.header, {
        [css.banner]: bannerMode,
        [css.centered]: centered,
        [css.primary]: primaryMode,
      }, className)
    }>
      {bannerMode && <Svg fillPrimary width={150} id="logoSmall" className={css.alt} />}
      <Tag>{renderValue(value)}</Tag>
      {(subHeader != null || children != null) && (
        <div className={css.bottom}>
          {subHeader != null && <SubHeaderTag>{renderValue(subHeader)}</SubHeaderTag>}
          {children}
        </div>
      )}
    </div>
  )
}

export default Header
