export const DEV = process.env.NEXT_PUBLIC_DEV === 'true'

export const HOST = process.env.NEXT_PUBLIC_HOST ?? 'https://www.propertyreach.com'

export const APP_HOST = `https://app${HOST.includes('-dev') ? '-dev' : ''}.propertyreach.com`

const { API_BASE_URL = '' } = process.env

export { API_BASE_URL }

export const SIGNUP_URL = `${APP_HOST}/signup`

export const LOGIN_URL = `${APP_HOST}/login`
