'use client'

import { useRouter } from 'next/navigation'
import { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { stripQueryString } from 'utils/http'

export type QueryCache = Record<string, any>

export interface QueryContextType {
  cache: QueryCache
  setCache: (cache: QueryCache) => void
}

export const QueryContext = createContext<QueryContextType>({
  cache: {},
  setCache: () => null,
})

const QueryProvider = QueryContext.Provider

export type QueryMethod = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE'

export type QueryStatus = 'Idle' | 'Complete' | 'Failed' | 'Fetching'

export type QueryCacheMapper<D, B> = (data: D, options: QueryOptions<D, B>, body: B | undefined) => object

export const idPath = (path: string, id: number | string | undefined | null) => `${path}?id=${id ?? ''}`

export const queryCacheMapper = (data: any, path: string) => data == null ? {} : { [path]: data }

export const idCacheMapper = (data: any, options: QueryOptions<any, any>) => {
  if (typeof data !== 'object' || data == null || data.id == null) return {}

  return { [idPath(options.path ?? '', data.id)]: data }
}

export interface QueryOptions<TData, TBody> {
  baseCacheEnabled?: boolean
  baseCachePath?: string
  cacheEnabled?: boolean
  cacheMapper?: QueryCacheMapper<TData, TBody>
  defaultBody?: TBody
  defaultData?: TData
  fetchEnabled?: boolean
  method?: QueryMethod
  path?: string
}

export interface QueryState<TData, TBody> {
  body?: TBody
  complete: boolean
  data: TData
  failed: boolean
  fetching: boolean
  loading: boolean
  refresh: () => void
  reset: () => void
  setBody: (body: TBody) => void
  status: QueryStatus
}

export type QueryOptionsParam<D, B> = string | QueryOptions<D, B> | undefined

const getQueryOptions = <D, B, > (options?: QueryOptionsParam<D, B>): QueryOptions<D, B> => {
  if (options === undefined) return {}
  if (typeof options === 'string') return { path: options }

  return options
}

export const useQuery = <TData, TBody = TData,> (options?: QueryOptionsParam<TData, TBody>): QueryState<TData, TBody> => {
  const opts = getQueryOptions(options)
  const {
    baseCacheEnabled = false,
    baseCachePath,
    cacheMapper,
    defaultBody,
    defaultData = {} as TData,
    method = 'GET',
    path = '',
  } = opts

  const { cache, setCache } = useContext(QueryContext)
  const [mergeCache, setMergeCache] = useState<any>({})
  const [body, setBody] = useState<TBody | undefined>(defaultBody)
  const [currentStatus, setStatus] = useState<QueryStatus>('Idle')
  const { push } = useRouter()
  // const currentBody = useRef<TBody | undefined>()

  const cacheEnabled = path !== '' && (opts.cacheEnabled ?? method === 'GET')
  const fetchEnabled = path !== '' && (opts.fetchEnabled ?? (method === 'GET' || body !== undefined))

  const [responseData, setResponseData] = useState<TData>()

  const cacheKey = useMemo(() => `${path}${body === undefined ? '' : JSON.stringify(body)}`, [path, body])

  // Construct "base cache" key; just the http method + endpoint URL sans query string. Useful if prior request's results should still
  // be returned even with different parameters. (E.g. when searching we'd leave prior search results while the next set loads.)
  const baseCacheKey = `${method}-${baseCachePath ?? stripQueryString(path)}`

  let id: any
  if (method === 'DELETE' && body !== undefined && 'id' in (body as any)) id = (body as any).id

  const url = `/api/${path}${id === undefined ? '' : `${path.includes('?') ? '&' : '?'}id=${id}`}`

  const fail = (status: QueryStatus = 'Failed') => setStatus(status)

  let status = currentStatus

  const refresh = () => {
    status = 'Fetching'
    setStatus(status)

    const fetchOpts = { method }

    fetch(url, ['GET', 'DELETE'].includes(method) || body === undefined ? fetchOpts : {
      ...fetchOpts,
      body: body instanceof FormData ? body : JSON.stringify(body),
      cache: 'no-cache',
    }).then(response => {
      const { status } = response

      // 401 will be returned if user is no longer authenticated (Session expires, account canceled, etc); bounce them.
      if (status === 401) push('/login')
      else {
        response.json().then(data => {
          setResponseData(data as TData)

          let mergeCache: any = {}
          if (cacheEnabled) mergeCache[cacheKey] = data
          if (baseCacheEnabled) mergeCache[baseCacheKey] = data
          if (cacheMapper != null) mergeCache = { ...mergeCache, ...cacheMapper(data, opts, body) }

          if (Object.keys(mergeCache).length > 0) setMergeCache(mergeCache)
          else setStatus('Complete')
        }).catch(fail)
      }
    }).catch(fail)
  }

  useMemo(() => {
    if (fetchEnabled
      && status !== 'Fetching'
      && (['GET', 'DELETE'].includes(method) || body != null)
      && (
        // (cacheEnabled && !(cacheKey in cache))
        (cacheEnabled && cache[cacheKey] === undefined)
        || !cacheEnabled
        //
      )) {
      // currentBody.current = body
      refresh()
    }

    return status
  }, [body, cacheEnabled, cacheKey, baseCacheEnabled, baseCacheKey, fetchEnabled])

  // Merge new data into cache (don't do this in promise result as it'll merge into stale cache in race conditions)
  useEffect(() => {
    if (Object.keys(mergeCache).length > 0) {
      setCache({ ...cache, ...mergeCache })
      setStatus('Complete')
    }
  }, [mergeCache])

  const data = useMemo(() => {
    if (cacheEnabled && cacheKey in cache) return cache[cacheKey]

    if (baseCacheEnabled && baseCacheKey in cache) return cache[baseCacheKey]

    return cacheEnabled || responseData === undefined ? defaultData : responseData
  }, [cache, cacheEnabled, cacheKey, responseData])

  const reset = () => {
    if (cacheEnabled) throw { message: 'Reset currently not supported on cached entries.' }
    setResponseData(undefined)
    setStatus('Idle')
    setBody(undefined)
  }

  return {
    body,
    complete: status === 'Complete',
    data,
    failed: status === 'Failed',
    fetching: status === 'Fetching',
    loading: status === 'Fetching',
    refresh,
    reset,
    setBody,
    status,
  }
}

const useQueryMethod = <D, B = D,> (method: QueryMethod, options?: QueryOptionsParam<D, B>,) => useQuery<D, B>({
  method,
  ...getQueryOptions(options),
})

export const usePostQuery = <D, B = D,> (options?: QueryOptionsParam<D, B>) => useQueryMethod<D, B>('POST', options)

export const usePutQuery = <D, B = D,> (options?: QueryOptionsParam<D, B>) => useQueryMethod<D, B>('PUT', options)

export const usePatchQuery = <D, B = D,> (options?: QueryOptionsParam<D, B>) => useQueryMethod<D, B>('PATCH', options)

export const useDeleteQuery = <D, B = D,> (options?: QueryOptionsParam<D, B>) => useQueryMethod<D, B>('DELETE', options)

export default QueryProvider
