import dayjs, { Dayjs } from 'dayjs'

export const isLeapYear = (year: number): boolean => ((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0)

export const parseMMDDYYYY = (value: string): Dayjs | undefined => {
  let date
  const matches = Array.from(value.trim().matchAll(/^(\d{1,2})\/(\d{1,2})\/(\d{2}|\d{4})$/g))
  if (matches.length === 1 && matches[0].length === 4) {
    const parts = matches[0]
    const month = Number(parts[1]) - 1
    const day = Number(parts[2])
    let year = Number(parts[3])

    if (year < 100) year += (year < 60 ? 2000 : 1900)
    const days = [31, isLeapYear(year) ? 29 : 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]

    if (year > 1000 && year < 3000 && month >= 0 && month < 12 && day >= 1 && day <= days[month]) date = dayjs(new Date(year, month, day))
  }

  return date
}

export const unixDateToISO = (date: number) => {
  let dt = new Date(date * 1000)
  dt = new Date(dt.getTime() - (dt.getTimezoneOffset() * 60 * 1000))

  return dt.toISOString().split('T')[0]
}

export const isDayjsSameOrBefore = (date1: Dayjs, date2: Dayjs) => date1.isSame(date2) || date1.isBefore(date2)

export const isDayjsSameOrAfter = (date1: Dayjs, date2: Dayjs) => date1.isSame(date2) || date1.isAfter(date2)
