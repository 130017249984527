'use client'

import classNames from 'classnames'
import Calendar from 'components/input/date-input/calendar'
import TextInput from 'components/input/text-input'
import { DateInputProps, TextInputContext } from 'components/input/types'
import { FC, useRef, useState } from 'react'

import css from './date-input.module.scss'

const DateInput: FC<DateInputProps> = ({
  calendar = 'focus',
  className,
  ...rest
}) => {
  const [context, setContext] = useState<TextInputContext>()
  const { focused = false, onChange, value } = context ?? {}

  return (
    <div className={classNames(css.dateInput, className)}>
      <TextInput {...rest} icon="calendar" onContextChange={setContext} type="date" />
      {focused && calendar === 'focus' && (
        <div className={css.calendarContainer}>
          <Calendar onChange={onChange} value={value} className={css.calendar} />
        </div>
      )}
    </div>
  )
}

export default DateInput
