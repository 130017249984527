import Checkbox from './checkbox'
import CheckboxGroup from './checkbox-group'
import DateInput from './date-input'
import RadioGroup from './radio-group'
import TextInput from './text-input'
import { InputDocument, InputItem, InputChangeEventHandler, InputChangeEvent, InputChangeEventOptions } from './types'

export const createInputChangeEvent = (
  name: string | undefined,
  value: any,
  options: InputChangeEventOptions = {}
): InputChangeEvent => ({
  name,
  value,
  changes: { [name ?? '']: value },
  ...options,
  checked: options.checked ?? false,
})

export const triggerInputChangeEvent = (handler: InputChangeEventHandler, name: string, value?: any): void => handler(createInputChangeEvent(name, value))

export type { InputDocument, InputItem, InputChangeEventHandler, InputChangeEvent }

export { Checkbox, CheckboxGroup, DateInput, RadioGroup, TextInput }
