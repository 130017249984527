import { MutableRefObject, RefObject, UIEvent } from 'react'

export const getScrollY = () => typeof window === 'undefined' ? 0 : window.scrollY

export const isWithinRef = (ref: RefObject<HTMLElement>, target: Event | EventTarget | null): boolean => ref.current?.contains((target instanceof Event ? target.target : target) as Node) ?? false

export const isOutsideRef = (ref: RefObject<HTMLElement>, target: Event | EventTarget | null): boolean => !isWithinRef(ref, target)

export const debounce = (callback: TimerHandler, ms: number = 1, timeout?: number): number => {
  if (timeout != null) clearTimeout(timeout)

  return setTimeout(callback, ms)
}

export const setTimer = (callback: TimerHandler, ms: number = 1, timer?: MutableRefObject<number | undefined>): number => {
  if (timer?.current !== undefined) clearTimeout(timer.current)

  const timerId = setTimeout(callback, ms)

  if (timer !== undefined) timer.current = timerId

  return timerId
}

export const stopEvent = <T extends UIEvent,>(handler?: (ev: T) => void): ((ev: T) => void) => (ev: T) => {
  ev.stopPropagation()
  ev.preventDefault()

  if (handler !== undefined) handler(ev)
}

export const stopHandler = stopEvent()

export const isElementScrollable = (el: Element): boolean =>
  // Scrollable content exists
  el.scrollHeight > el.clientHeight
  // Overflow is not hidden
  && window.getComputedStyle(el).overflowY.indexOf('hidden') === -1

export const getScrollableParent = (el: Element): Element | null => {
  return el == null || el === document.body
    ? document.body
    : isElementScrollable(el as Element)
      ? el
      : getScrollableParent(el.parentNode as Element);
}
