'use client'

import classNames from 'classnames'
import {
  createInputChangeEvent,
  InputChangeEvent,
  InputChangeEventHandler, InputItem,
  RadioGroup,
  TextInput
} from 'components/input/index'
import InputWrapper from 'components/input/input-wrapper'
import { CheckboxGroupProps, InputCategory } from 'components/input/types'
import usePagedInput from 'components/input/usePagedInput'
import { getMultiValueList } from 'components/input/utils'
import { useQuery } from 'components/query'
import useBoolean from 'hooks/useBoolean'
import React, { FC, KeyboardEvent, useRef, useState } from 'react'
import { IoChevronDown } from 'react-icons/io5'
import { stopHandler } from 'utils/dom'
import { formatNumber } from 'utils/format'
import Checkbox from '../checkbox'

import css from './checkbox-group.module.scss'

const CheckboxGroupCategory: FC<{
  category: InputCategory
  disabled: boolean | undefined
  dropdown: boolean
  defaultExpanded: boolean
  focus: boolean | undefined
  handleCategoryChange: (category: any, ev: InputChangeEvent) => void
  handleChange: InputChangeEventHandler
  name: string | undefined
  value: any
}> = ({
  category,
  defaultExpanded,
  dropdown,
  focus,
  handleCategoryChange,
  handleChange,
  name,
  value,
}) => {
  const { value: categoryCode, selected, items, enabledItems } = category
  const expandable = categoryCode !== undefined
  const [expanded, setExpanded] = useState(defaultExpanded)

  return (
    <div
      className={classNames(css.category, { [css.expanded]: expanded })}
      onMouseDown={stopHandler}
      key={categoryCode}
    >
      {expandable && (
        <div className={css.categoryTitle}>
          <div className={css.label} onClick={() => setExpanded(!expanded)}>
            <IoChevronDown size={22} />
            {categoryCode}
          </div>
          <Checkbox
            disabled={enabledItems.length === 0}
            label={dropdown ? undefined : 'Select All'}
            onChange={ev => handleCategoryChange(categoryCode, ev)}
            checked={selected}
            suppressFocus
          />
        </div>
      )}
      {(!expandable || expanded) && (
        <div className={css.items}>
          {items.map(({ value: val, label = val, quantity }, i) => (
            <Checkbox
              checked={val === value || (Array.isArray(value) && value.includes(val))}
              checkedValue={val}
              className={css.item}
              disabled={quantity === 0}
              focus={focus === true && i === 0}
              key={val}
              label={`${label}${quantity === undefined ? '' : ` (${formatNumber(quantity)})`}`}
              name={name}
              onChange={handleChange}
            />
          ))}
        </div>
      )}
    </div>
  )
}

const CheckboxGroup: FC<CheckboxGroupProps> = ({
  className,
  defaultExpandedCategories = [],
  disabled,
  dual = false,
  single = false,
  // entryClassName,
  entryRef,
  focus = false,
  items: clientItems,
  name,
  onChange,
  placeholder = 'Any',
  searchUrl,
  type = 'panel',
  label,
  value: inputValue,
  ...rest
}) => {
  const dropdown = type === 'dropdown'
  const [opened, open, close] = useBoolean(false)
  const dropdownRef = useRef<HTMLDivElement | null>(null)

  const { data: serverItems, loading } = useQuery<InputItem[]>({
    defaultData: [],
    path: searchUrl,
  })

  const items = searchUrl === undefined || loading ? clientItems : serverItems

  const { value, pages, activePage, handleChange, handlePageChange, handleCategoryChange } = usePagedInput(items, name, inputValue, onChange, dropdown)
  const { categories } = activePage
  const pagesVisible = pages.length > 1

  const handleKeyDown = (ev: KeyboardEvent) => {
    const { key } = ev

    if (!opened && (key.length === 1 || ['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(key))) open()
    else if (onChange !== undefined && ['Backspace', 'Delete'].includes(key)) onChange(createInputChangeEvent(name, undefined))
    else if (opened && key === 'Escape') {
      close()
      ev.stopPropagation()
    }
  }

  const renderCategories = () => {
    return categories.map(category => (
      <CheckboxGroupCategory
        category={category}
        defaultExpanded={defaultExpandedCategories.includes(category.value)}
        disabled={disabled}
        dropdown={dropdown}
        focus={focus}
        handleCategoryChange={handleCategoryChange}
        handleChange={handleChange}
        key={category.value}
        name={name}
        value={value}
      />
    ))
  }

  return (
    <div className={classNames(css.checkboxGroup, css[`type-${type}`], { [css.dual]: dual, [css.single]: single }, className)} ref={entryRef}>
      {type === 'panel' && (
        <>
          {pagesVisible && (
            <RadioGroup
              className={css.pages}
              items={pages}
              label={label}
              onChange={handlePageChange}
              type="tablet"
              value={activePage.value}
              full
            />
          )}
          {categories.length > 0 && (
            <InputWrapper label={pagesVisible ? undefined : label} {...rest}>
              <div>{renderCategories()}</div>
            </InputWrapper>
          )}
        </>
      )}
      {type === 'dropdown' && (
        <div ref={dropdownRef}>
          <TextInput
            {...rest}
            icon="caret"
            label={label}
            onBlur={close}
            onFocus={open}
            onKeyDown={handleKeyDown}
            placeholder={placeholder}
            readOnly
            value={getMultiValueList(items, value, ' / ')}
            wrapperType="border"
          />
          {!disabled && opened && (
            <div className={css.checkboxDropdownContainer}>
              <div className={css.checkboxDropdown} onMouseDown={stopHandler}>
                {renderCategories()}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default CheckboxGroup
