import Head from 'next/head'
import React, { FC } from 'react'
import hash from 'string-hash'

const titlePatterns: Array<{ regExp: RegExp, replacement: string }> = [
  { regExp: /\bReverend\b/gi, replacement: 'Rev.'},
  { regExp: /\bHighway\b/gi, replacement: 'Hwy'},
  { regExp: /\bBetween\b/gi, replacement: 'Btw'},
  { regExp: /\bParkway\b/gi, replacement: 'Pkwy'},
  { regExp: /\bCreek\b/gi, replacement: 'Crk'},
  { regExp: /\bAcres Between\b/gi, replacement: 'Ac/Btw'},
  { regExp: /\bAcres On\b/gi, replacement: 'AO'},
  { regExp: /\bCorner Of\b/gi, replacement: 'C/O'},
  { regExp: /\bAc\/Btw\b/gi, replacement: 'A/B'},
  { regExp: /\bAO C\/O\b/gi, replacement: 'AoCo'},
  { regExp: /\bC\/O\b/gi, replacement: '%'},
  { regExp: /\bRoad\b/gi, replacement: 'Rd'},
  { regExp: /\bAKA\b/gi, replacement: '/'},
]

const MetaInfo: FC<{
  children?: React.ReactNode
  description?: string
  ldSchema?: object
  ldSchema2?: object
  location?: string
  suppressCompany?: boolean
  title?: string | string[]
}> = ({
  children,
  description,
  ldSchema,
  ldSchema2,
  location = '',
  suppressCompany = false,
  title,
}) => {
  const loc = location.replace(/,(?= [A-Z]{2}\b)/, '')

  let titleText

  if (title) {
    titleText = [
      (typeof title === 'string' ? title : title[hash(location) % title.length]).replace('{loc}', loc),
      suppressCompany ? '' : ' | PropertyReach'
    ].join('')
      .replace(/,/g, '')
      .replace(/\band\b/gi, '&')
      .replace(/\bA\s+K\s+A\b/gi, 'AKA')

    for (let i = 0; titleText.length > 60 && i < titlePatterns.length; i++) {
      const { regExp, replacement } = titlePatterns[i]
      titleText = titleText.replace(regExp, replacement)
    }
  }

  return (
    <Head>
      {titleText !== undefined && (
        <title>
          {titleText}
        </title>
      )}
      {description && <meta name="description" content={description.replace('{loc}', loc)} />}
      {ldSchema && <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(ldSchema) }} />}
      {ldSchema2 && <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(ldSchema2) }} />}
      {children}
    </Head>
  )
}

export default MetaInfo
