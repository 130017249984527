'use client'

import { CSSProperties, FC, MutableRefObject, ReactNode, useRef } from 'react'
import { Portal } from 'react-portal'

import css from './bubble.module.scss'

export type BubbleContext = {
  content: ReactNode
  ref: MutableRefObject<HTMLDivElement | null>
}

const Bubble: FC<{
  context: BubbleContext
}> = ({
  context,
}) => {
  const { content } = context
  const ref = useRef<HTMLDivElement | null>(null)
  const contentRef = useRef<HTMLDivElement | null>(null)

  let style: CSSProperties | undefined

  const el = ref.current
  const contentEl = contentRef.current
  if (el != null && contentEl != null) {
    const { bottom, left } = el.getBoundingClientRect()
    style = { top: bottom + 10, left: left, width: contentEl.clientWidth }
  }

  return (
    <>
      <div className={css.bubbleMeasurement} ref={ref}>
        <div className={css.bubble} ref={contentRef}>
          <div className={css.inner}>{content}</div>
        </div>
      </div>
      {style !== undefined && (
        <Portal>
          <div className={css.bubble} style={style}>
            <div className={css.inner}>{content}</div>
          </div>
        </Portal>
      )}
    </>
  )
}

export default Bubble
