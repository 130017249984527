import classNames from 'classnames'
import { useRouter } from 'next/router'
import React, { FC, MouseEvent, MouseEventHandler, useRef } from 'react'
import { RotatingLines } from 'react-loader-spinner'
import { UrlObject } from 'url'
import css from './button.module.scss'

const Button: FC<{
  ariaLabel?: string
  children?: any
  className?: string
  disabled?: boolean
  href?: string | UrlObject
  loading?: boolean
  onClick?: MouseEventHandler
  solid?: boolean
  submit?: boolean
  target?: string
}> = ({
  ariaLabel,
  children,
  className,
  disabled,
  href,
  loading = false,
  onClick,
  solid = false,
  submit = false,
  target,
}) => {
  const { push } = useRouter()

  const formMode = target != null && typeof href === 'string' && href.startsWith('http')

  const formRef = useRef<HTMLFormElement>(null)

  const handleClick = (ev: MouseEvent) => {
    if (onClick != null) onClick(ev)
    if (href != null) {
      if (!formMode) void push(href)
      else if (formRef.current != null) formRef.current.submit()
    }
  }

  return (
    <>
      <button
        aria-label={ariaLabel}
        className={classNames(css.button, {
          [css.loading]: loading,
          [css.solid]: solid
        }, className)}
        disabled={disabled ?? (onClick == null && href == null && !submit)}
        onClick={handleClick}
        type={submit ? 'submit' : 'button'}
      >
        <span className={css.content}>
          {children}
        </span>
        {loading && (
          <span className={css.spinner}>
            <RotatingLines strokeColor="white" animationDuration="0.8" strokeWidth="3" width="30" />
          </span>
        )}
      </button>
      {formMode && <form action={href} target={target} ref={formRef} style={{ display: 'none' }} />}
    </>
  )
}

export default Button
