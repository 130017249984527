import { MutableRefObject, useEffect, useRef } from 'react'

const useFocusRef = (): MutableRefObject<HTMLElement | undefined> => {
  const ref = useRef<HTMLElement>()

  useEffect(() => {
    const r = ref.current as HTMLInputElement

    if (r != null) {
      if (r.select != null) r.select()
      else r.focus()
    }
  }, [ref])

  return ref
}

export default useFocusRef
