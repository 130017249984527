import NextLink from 'next/link'
import React, { FC, ForwardedRef, MouseEventHandler, RefObject } from 'react'
import { UrlObject } from 'url'

/**
 * NextJS Link Component with prefetch defaulted to false.
 *
 * "data-saver"? https://nextjs.org/docs/messages/prefetch-true-deprecated
 */
const Link = React.forwardRef(({
  ariaLabel,
  children,
  className,
  href,
  onClick,
  prefetch = false,
  tabIndex,
  target,
}: {
  ariaLabel?: string
  children?: any
  className?: string
  href: string | UrlObject
  onClick?: MouseEventHandler
  prefetch?: boolean
  ref?: RefObject<HTMLAnchorElement>
  tabIndex?: number
  target?: string
}, ref: ForwardedRef<HTMLAnchorElement>) => (
  <NextLink
    aria-label={ariaLabel}
    className={className}
    href={href}
    onClick={ev => { ev.stopPropagation(); if (onClick != null) onClick(ev); }}
    prefetch={!prefetch ? false : undefined}
    ref={ref}
    tabIndex={tabIndex}
    target={target}
  >
    {children}
  </NextLink>
))

Link.displayName = 'Link'

export default Link
