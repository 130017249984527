'use client'

import classNames from 'classnames'
import Spinner from 'components/spinner'
import React, { FC } from 'react'

import css from './suspense.module.scss'

export type SuspenseProps = {
  className?: string
  message: string
  detail?: string
  visible?: boolean
}

const Suspense: FC<SuspenseProps> = ({
  className,
  message,
  detail,
  visible = false,
}) => {
  return (
    <div className={classNames(css.suspense, { [css.visible]: visible }, className)}>
      <div className={css.inner}>
        <Spinner />
        <h2>{message}</h2>
        {detail !== undefined && <div>{detail}</div>}
      </div>
    </div>
  )
}

export default Suspense
