import Link from 'components/Link'
import Svg from 'components/Svg'
import dayjs from 'dayjs'
import React, { FC } from 'react'
import { IconType } from 'react-icons'
import { FaFacebookSquare, FaInstagram, FaLinkedin, FaYoutube } from 'react-icons/fa'
import { FaXTwitter } from 'react-icons/fa6'
import css from './footer.module.scss'

const socials: {
  Icon: IconType
  title: string
  url: string
}[] = [
  { Icon: FaFacebookSquare, title: 'FaceBook', url: 'https://www.facebook.com/PropertyReachOfficial' },
  { Icon: FaXTwitter, title: 'X', url: 'https://x.com/property_reach' },
  { Icon: FaLinkedin, title: 'LinkedIn', url: 'https://www.linkedin.com/company/propertyreach' },
  { Icon: FaInstagram, title: 'Instagram', url: 'https://www.instagram.com/propertyreachofficial' },
  { Icon: FaYoutube, title: 'YouTube', url: 'https://www.youtube.com/@PropertyReach' },
]

const Footer: FC = () => {

  return (
    <div className={css.frame}>
      <Link ariaLabel="Home" href="/" className={css.logo}>
        <Svg fillPrimary width={230} id="logoLight" />
      </Link>
      <div className={css.body}>
        <div className={css.description}>
          <div>Your All-in-one Property Data Software Solution</div>
          Identify lucrative investment opportunities, gaining a competitive edge in
          negotiations, and understanding market trends like never before. With
          PropertyReach, you&apos;ll have a comprehensive view of property values,
          rental rates, historical tax and sales data, distressed situations and much
          more &ndash; all at your fingertips.
        </div>
        <div className={css.links}>
          <div className={css.left}>
            <Link href="/contact">Contact Us</Link>
            <Link href="/leadership">Leadership Team</Link>
            <Link href="https://blog.propertyreach.com/real-estate-news">Property Investing Blog</Link>
            <Link href="/features">PropertyReach Features</Link>
            <Link href="/faq">Frequently Asked Questions</Link>
            <Link href="/property-api">Property API</Link>
          </div>
          <div className={css.right}>
            <div className={css.top}>
              <Link href="/terms">Terms of Use</Link>
              <Link href="/privacy">Privacy Policy</Link>
              <Link href="/privacy-rights">Do Not Sell My Personal Information</Link>
            </div>
            <div className={css.bottom}>
              {socials.map(({ Icon, title, url }) => (
                <Link key={title} href={url} target={title}>
                  <Icon size={25} title={title} />
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className={css.copyright}>&copy; {dayjs().year()} PropertyReach, Inc. All rights reserved.</div>
    </div>
  )
}

export default Footer
