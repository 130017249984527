import { NextRequest } from 'next/server'

export const getCookieValue = (request: Request, name: string): string | undefined => {
  return request.headers.get('cookie')?.split(';').find(cookie => cookie.trim().startsWith(`${name}=`))?.trim().substring(name.length + 1)
  // return request.cookies.get(name)?.value // NextRequest
}

export const stripQueryString = (url: string) => url.substring(0, url.includes('?') ? url.indexOf('?') : url.length)

export const applyQueryString = (url: string, params: any) => `${url}${url.includes('?') ? '&' : '?'}${new URLSearchParams(params).toString()}`

export const ipFromXForwardedFor = (xForwardedFor: string | null): string | undefined => {
  // Attempt to extract the IP address from the potentially comma-delimited list of identifiers found in the forwarded-for header
  let ip: string | undefined
  if (xForwardedFor != null) {
    const vals = xForwardedFor.split(',')
    ip = vals.length === 1 ? vals[0] : vals.find(val => /\d+\.\d+\.\d+\.\d+/g.test(val))
  }

  return ip
}

export const ipFromNextRequest = (request: NextRequest) => ipFromXForwardedFor(request.headers.get('x-forwarded-for'))
