'use client'

import classNames from 'classnames'
import Bubble from 'components/bubble'
import Help from 'components/help'
import { InputWrapperProps } from 'components/input/types'
import { FC } from 'react'
import css from './input-wrapper.module.scss'
import css2 from '../input-panel/input-panel.module.scss'

const InputWrapper: FC<InputWrapperProps> = ({
  bubbleContext,
  children,
  className,
  componentWrapperClassName,
  description,
  entryRef,
  // focus = false,
  full = false,
  label,
  labelChildren,
  detail,
  onClick,
  style,
  suppressLabel = false,
  wrapperType = 'none',
}) => {
  return (
    <div
      className={classNames(css.inputWrapper, css[`type-${wrapperType}`], {
        [css.divided]: Array.isArray(children),
        [css2.full]: full,
      }, className)}
      onClick={onClick}
      ref={entryRef}
      style={style}
    >
      {label != null && !suppressLabel && (
        <div className={css.label}>
          <div className={css.labelContent}>
            <div>
              {label !== '' && detail !== 'or' && <label>{label}</label>}
              {detail != null && <span className={css.labelDetail}>{detail}</span>}
            </div>
            {description != null && <Help className={css.help}>{description}</Help>}
          </div>
          {labelChildren != null && <div className={css.labelChildren}>{labelChildren}</div>}
        </div>
      )}
      <div className={css.components}>
        <div className={css.componentsInner}>
          {!Array.isArray(children) ? children : children.map((child, i) => (
             <div
               className={classNames(css.componentWrapper, componentWrapperClassName)}
               key={`item-${i}`}
             >
               {child}
             </div>
          ))}
        </div>
      </div>
      {bubbleContext !== undefined && <Bubble context={bubbleContext} />}
    </div>
  )
}

export default InputWrapper
