'use client'

import classNames from 'classnames'
import { CheckboxProps } from 'components/input/types'
import useToggleInput from 'components/input/useToggleInput'
import { FC, useEffect, useRef } from 'react'
import { BiCheck } from 'react-icons/bi'
import css from './checkbox.module.scss'

const Checkbox: FC<CheckboxProps> = props => {
  const {
    className,
    disabled = false,
    focus,
    label,
    suppressFocus = false,
  } = props
  const { checked, checkedValue, focused, input, triggerChange } = useToggleInput(props)
  const ref = useRef<HTMLLabelElement | null>(null)

  useEffect(() => {
    if (focus) ref.current?.focus()
  }, [focus])

  return (
    <label
      className={classNames(css.checkbox, {
        [css.checked]: checked,
        [css.disabled]: disabled,
        [css.focused]: focused,
      }, className)}
      onClick={suppressFocus ? () => triggerChange(!checked) : undefined}
      ref={ref}
    >
      {!suppressFocus && input}
      <span className={css.markerContainer}>
        {checked === true && <BiCheck size={16} />}
        {checked === null && <div className={css.partialMarker} />}
      </span>
      <span>{label ?? (typeof checkedValue === 'string' || typeof checkedValue === 'number' ? checkedValue : '')}</span>
    </label>
  )
}

export default Checkbox
