import { createInputChangeEvent } from 'components/input/index'
import {
  InputCategory,
  InputChangeEvent,
  InputChangeEventHandler,
  InputItem,
  InputPage,
  PageDef, PagedInputData
} from 'components/input/types'
import { updateMultiValue, useInputValue } from 'components/input/utils'
import { useMemo, useState } from 'react'

const usePagedInput = (
  items: InputItem[],
  name: string | undefined,
  inputValue: any,
  onChange: InputChangeEventHandler | undefined,
  singlePage = false,
): PagedInputData => {
  const { value } = useInputValue(inputValue, name)
  const [pageCode, setPageCode] = useState<any>()

  const handleChange = ({ checked, checkedValue }: InputChangeEvent): void => {
    if (onChange != null) updateMultiValue(onChange, name, items, value, checkedValue, checked)
  }

  const handlePageChange = (ev: InputChangeEvent) => {
    setPageCode(ev.value)

    // "All" item selected; clear any selection
    if (ev.value === 'All' && onChange != null) onChange(createInputChangeEvent(name, undefined))
  }

  const handleCategoryChange = (categoryCode: any, ev: InputChangeEvent) => {
    let category: InputCategory | undefined = activePage.categories.find(c => c.value === categoryCode)
    if (onChange != null && category !== undefined) {
      category.selected = ev.checked
      updateMultiValue(onChange, name, items, value, category.items.map(item => item.value), ev.checked)
    }
  }

  const pages: InputPage[] = useMemo(() => {
    const pageDefMap: Record<any, PageDef | undefined> = {}
    // const allCategory: InputCategory = { items: [], selected: false }

    const pages: InputPage[] = items.reduce((pages, item) => {
      const { page: code, category: categoryCode } = item
      const pageCode = singlePage ? 'All' : code
      let pageDef = pageDefMap[pageCode ?? '']
      if (pageDef === undefined) {
        pageDef = { page: { value: pageCode, categories: [] }, categoryMap: {} }
        pageDefMap[pageCode ?? ''] = pageDef
        pages.push(pageDef.page)
      }

      let category = pageDef.categoryMap[categoryCode ?? '']
      if (category === undefined) {
        category = { value: categoryCode, items: [], enabledItems: [], selected: false }
        pageDef.page.categories.push(category)
        pageDef.categoryMap[categoryCode ?? ''] = category
      }

      category.items.push(item)
      if (!item.disabled) category.enabledItems.push(item)

      return pages
    }, [] as InputPage[])

    if (pages.length === 0) pages.push({ value: 'All', categories: [{ items: [], enabledItems: [], selected: false }] })
    else if (pages.length > 1) pages.unshift({ value: 'All', categories: [] })

    return pages
  }, [items, singlePage])

  const activePage = useMemo(() => {
    const page = pages.find(p => p.value === pageCode) ?? pages[0]

    return {
      ...page,
      categories: page.categories.map(category => {
        const items = category.enabledItems
        const selectedCount = items.filter(item => Array.isArray(value) && value.includes(item.value)).length

        return {
          ...category,
          selected: selectedCount > 0 && selectedCount < items.length ? null : selectedCount > 0 && selectedCount === items.length,
        }
      }),
    }
  }, [pages, pageCode, value])

  return {
    activePage,
    handleChange,
    handlePageChange,
    handleCategoryChange,
    pages,
    value,
  }
}

export default usePagedInput
