import useWindowScroll from 'components/useWindowScroll'
import { RefObject, useState } from 'react'
import { IconType } from 'react-icons'
import { debounce, getScrollableParent, getScrollY } from 'utils/dom'

type MenuKey = number | string

interface MenuItemConfig {
  available?: boolean
  Icon: IconType
  key: MenuKey
  label: string
  menuLabel?: string
  quantity?: number
  ref: RefObject<HTMLDivElement>
}

interface MenuItem extends MenuItemConfig{
  available: boolean
}

let manualLockTimeout: number | undefined

function useScrollMenu(items: MenuItemConfig[], scrollOffset: number): {
  activeKey?: MenuKey
  closeMenu: () => void
  highlightKey?: MenuKey
  menuItems: MenuItem[]
  menuItemMap: Partial<Record<MenuKey, MenuItem>>
  menuOpened: boolean
  // menuVisible: boolean
  openMenu: () => void
  scrolling: boolean
  scrollTo: (key: MenuKey) => void
} {
  const [menuOpened, setMenuOpened] = useState(false)
  // const [menuVisible, setMenuVisible] = useState(false)
  const [manualKey, setManualKey] = useState<MenuKey>()
  const [scrolling, setScrolling] = useState(getScrollY() > scrollOffset)
  const [scrollKey, setScrollKey] = useState<MenuKey>()
  const activeKey = manualKey ?? scrollKey
  const [highlightKey, setHighlightKey] = useState<MenuKey>()

  const menuItemMap: Partial<Record<MenuKey, MenuItem>> = {}
  const menuItems = items.reduce((items, config) => {
    const { available, key, Icon, label, menuLabel, quantity, ref } = config

    const item: MenuItem = {
      available: available ?? (quantity ?? 0) != 0,
      Icon,
      key,
      label,
      menuLabel,
      quantity,
      ref
    }

    if (item.available) {
      menuItemMap[key] = item
      items.push(item)
    }

    return items
  }, [] as MenuItem[])

  const openMenu = () => {
    setMenuOpened(true)
    // setTimeout(() => setMenuVisible(true), 1)
  }

  const closeMenu = () => {
    // setMenuVisible(false)
    // setTimeout(() => setMenuOpened(false), 300)
    setMenuOpened(false)
  }

  const setManualLockTimeout = () => {
    debounce(() => manualLockTimeout = undefined, 100, manualLockTimeout)
  }

  const scrollTo = (key: MenuKey) => {
    closeMenu()
    setManualKey(key)
    setManualLockTimeout()

    setHighlightKey(key)
    debounce(() => setHighlightKey(undefined))

    const el = menuItemMap[key]?.ref.current
    if (el != null) {
      const scrollParent = getScrollableParent(el)
      if (scrollParent != null) {
        scrollParent.scrollTo({ behavior: 'smooth', top: el.offsetTop - 100 })
      }
    }
  }

  useWindowScroll((ev) => {
    setScrolling(getScrollY() > scrollOffset)

    if (manualLockTimeout != null) setManualLockTimeout()
    else {
      setManualKey(undefined)

      // Set active section to first fully visible section, or last section if scrolled to bottom
      const topItem = menuItems.find(({ key, ref }) => {
        const el = ref.current
        if (el == null) return false

        return el.getBoundingClientRect().top - scrollOffset + 20 > 0
      })

      setScrollKey((topItem ?? menuItems[menuItems.length - 1]).key)
    }
  })

  return {
    activeKey,
    closeMenu,
    highlightKey,
    menuItems,
    menuItemMap,
    menuOpened,
    // menuVisible,
    openMenu,
    scrolling,
    scrollTo
  }
}

export default useScrollMenu
