import { createInputChangeEvent } from 'components/input/index'
import { ToggleProps } from 'components/input/types'
import { useInputValue } from 'components/input/utils'
import { ReactNode, useState } from 'react'

export interface ToggleInputData {
  checked: boolean | null
  checkedValue: any
  focused: boolean
  input: ReactNode
  triggerChange: (checked: boolean) => void
  uncheckedValue: any
}

/**
 * Common functionality for binary toggle input controls (Checkbox, Toggle)
 */
const useToggleInput = (props: ToggleProps): ToggleInputData => {
  const {
    checked: manualChecked,
    checkedValue: manualCheckedValue,
    disabled,
    name,
    onChange,
    uncheckedValue: manualUncheckedValue,
    value: inputValue,
  } = props

  let checkedValue = manualCheckedValue
  let uncheckedValue = manualUncheckedValue
  if (checkedValue === undefined && uncheckedValue === undefined) {
    checkedValue = true
    uncheckedValue = false
  }

  const [focused, setFocused] = useState(false)
  const { value } = useInputValue(inputValue, name)
  const checked = manualChecked === undefined ? value === checkedValue : manualChecked

  const triggerChange = (checked: boolean) => {
    const value = checked ? checkedValue : uncheckedValue

    if (onChange != null) onChange(createInputChangeEvent(name, value, { checked, checkedValue, uncheckedValue }))
  }

  const input = (
    <input
      checked={checked !== false}
      disabled={disabled}
      name={name}
      onBlur={() => setFocused(false)}
      onChange={ev => triggerChange(ev.target.checked)}
      onFocus={() => setFocused(true)}
      type="checkbox"
    />
  )

  return {
    checked,
    checkedValue,
    focused,
    input,
    triggerChange,
    uncheckedValue,
  }
}

export default useToggleInput
