import Labeled from 'components/Labeled'
import classNames from 'classnames'
import { InputChangeEvent } from 'components/types'
import { ValidationContext } from 'components/validation'
import React, { CSSProperties, FC, KeyboardEvent, useEffect, useRef, useState } from 'react'

export interface InputTextProps {
  autoFocus?: boolean
  className?: string
  disabled?: boolean
  inputRef?: React.MutableRefObject<HTMLElement | undefined>
  label?: string
  name?: string
  password?: boolean
  placeholder?: string
  onApply?: (ev: InputChangeEvent) => void
  onChange?: (ev: InputChangeEvent) => void
  onReset?: (ev: InputChangeEvent) => void
  readOnly?: boolean
  required?: boolean
  rows?: number
  style?: CSSProperties
  validationContext?: ValidationContext
  value?: any
}

const InputText: FC<InputTextProps> = ({
  autoFocus = false,
  className,
  disabled = false,
  inputRef,
  label,
  name = '',
  onApply,
  onChange,
  onReset,
  password = false,
  placeholder,
  readOnly,
  required = false,
  rows = 1,
  style,
  validationContext,
  value
}) => {
  const [manualValue, setManualValue] = useState('')

  let val = typeof value === 'object' ? value[name] : value
  if (val != null) val = String(val)

  if (val === undefined) val = manualValue

  const getChange = (value: string = val) => ({ name, value, changes: { [name]: value } })

  const handleChange = (value: string): void => {
    if (onChange != null) onChange(getChange(value))
    else setManualValue(value)
  }

  const handleBlur = () => {
    if (onApply != null) onApply(getChange())
  }

  const handleKeyDown = (ev: KeyboardEvent) => {
    if (ev.code === 'Enter' && onApply != null) onApply(getChange())
    else if (ev.code === 'Escape' && onReset != null) onReset(getChange())
  }

  const defaultRef = useRef<HTMLInputElement>()
  const ref = inputRef ?? validationContext?.refs[name] ?? defaultRef
  const classes = classNames(className)

  useEffect(() => {
    if (autoFocus && ref?.current != null) {
      setTimeout(() => ref.current?.focus(), 100)
    }
  }, [autoFocus, ref]);

  let input
  if (rows > 1) {
    input = (
      <textarea
        className={classes}
        disabled={disabled}
        name={name}
        onBlur={handleBlur}
        onChange={ev => handleChange(ev.target.value)}
        onKeyDown={handleKeyDown}
        placeholder={placeholder}
        readOnly={readOnly}
        ref={ref as React.MutableRefObject<HTMLTextAreaElement>}
        rows={rows}
        style={style}
      >{val}</textarea>
    )
  } else {
    input = (
      <input
        className={classes}
        disabled={disabled}
        onBlur={handleBlur}
        name={name}
        onChange={ev => handleChange(ev.target.value)}
        onKeyDown={handleKeyDown}
        placeholder={placeholder}
        readOnly={readOnly}
        ref={ref as React.MutableRefObject<HTMLInputElement>}
        style={style}
        type={password ? 'password' : 'text'}
        value={val}
      />
    )
  }

  return label == null ? input : (
    <Labeled label={label} required={required}>
      {input}
    </Labeled>
  )
}

export default InputText
