'use client'

import classNames from 'classnames'
import Help from 'components/help'
import { createInputChangeEvent, InputChangeEvent, InputChangeEventHandler, InputItem } from 'components/input'
import InputWrapper from 'components/input/input-wrapper'
import { InputWrapperType, ItemClickHandler, RadioGroupProps } from 'components/input/types'
import { useInputValue } from 'components/input/utils'
import useMediaInfo from 'hooks/useMediaInfo'
import { FC, MouseEvent, useEffect, useRef, useState } from 'react'
import css from './radio-group.module.scss'

export type RadioGroupType = 'bubble' | 'button' | 'circle' | 'link' | 'pill' | 'tab' | 'tablet' | 'tile'

type RadioGroupTypeDef = {
  manualWidth: boolean,
  transitionProperty: string,
  wrapperType: InputWrapperType,
  helpDescription: boolean,
}

const radioGroupTypes: Record<RadioGroupType, RadioGroupTypeDef> = {
  bubble: { manualWidth: false, wrapperType: 'none', transitionProperty: 'all', helpDescription: true },
  button: { manualWidth: false, wrapperType: 'none', transitionProperty: 'all', helpDescription: false },
  circle: { manualWidth: false, wrapperType: 'round-border', transitionProperty: 'all', helpDescription: false },
  link: { manualWidth: false, wrapperType: 'none', transitionProperty: 'none', helpDescription: false },
  pill: { manualWidth: false, wrapperType: 'none', transitionProperty: 'all', helpDescription: false },
  tab: { manualWidth: false, wrapperType: 'none', transitionProperty: 'none', helpDescription: false },
  tablet: { manualWidth: true, wrapperType: 'border', transitionProperty: 'all', helpDescription: false },
  tile: { manualWidth: false, wrapperType: 'none', transitionProperty: 'all', helpDescription: false },
}

const RadioButton: FC<{
  focus: boolean
  item: InputItem
  name: string | undefined
  onChange: InputChangeEventHandler | undefined
  onClick: ItemClickHandler | undefined
  selected: boolean
  setActiveMarker: (el: HTMLLabelElement | null) => void
  type: RadioGroupType
  typeDef: RadioGroupTypeDef
  width: string | undefined
}> = ({
  focus,
  item,
  name,
  onChange,
  onClick,
  selected,
  setActiveMarker,
  type,
  typeDef,
  width,
}) => {
  const { description, Icon, label, value, disabled = false, children } = item
  const { helpDescription } = typeDef
  const text = label ?? String(value) ?? ''
  const ref = useRef<HTMLLabelElement | null>(null)

  const handleChange = () => {
    if (onChange != null) onChange(createInputChangeEvent(name, item.value))
  }

  const handleClick = (ev: MouseEvent) => {
    if (onClick != null && !disabled) onClick(item, ev)
  }

  useEffect(() => {
    if (selected) setActiveMarker(ref.current)
  }, [selected])

  useEffect(() => {
    if (focus) ref.current?.focus()
  }, [focus])

  return (
    <label
      className={classNames(css.item, {
        [css.selected]: selected,
        [css.disabled]: disabled,
      })}
      onClick={handleClick}
      ref={ref}
      style={{ width }}
    >
      <input checked={selected} name={name} onChange={handleChange} type="radio" value={value} disabled={disabled} />
      {type === 'bubble' && <span className={css.bubble} />}
      {Icon != null && <Icon size={20} />}
      {text !== '' && (
        <span className={css.text}>
          <span className={css.label}>{text}</span>
          {description != null && !helpDescription && <span className={css.description}>{description}</span>}
        </span>
      )}
      {children}
      {helpDescription && description != null && (
        <span className={css.help}>
          <Help>{description}</Help>
        </span>
      )}
    </label>
  )
}

const RadioGroup: FC<RadioGroupProps> = ({
  entryClassName,
  entryRef,
  focus = false,
  items,
  layout,
  name,
  onChange,
  onItemClick,
  type: inputType,
  value: inputValue,
  ...rest
}) => {
  const [activeMarker, setActiveMarker] = useState<HTMLLabelElement | null>()
  const ref = useRef<HTMLDivElement | null>(null)
  const { value } = useInputValue(inputValue, name)
  const type = inputType ?? 'bubble' // TODO: Why does ts complain when default prop value used?
  const typeDef = radioGroupTypes[type]
  const { manualWidth, transitionProperty, wrapperType } = typeDef

  const updateMarkerPos = () => {
    const el = ref?.current
    if (el != null) {
      if (activeMarker != null) {
        el.style.setProperty('--marker-left', `${activeMarker.offsetLeft}px`)
        el.style.setProperty('--marker-top', `${activeMarker.offsetTop}px`)
        el.style.setProperty('--marker-width', `${activeMarker.offsetWidth}px`)
        el.style.setProperty('--marker-height', `${activeMarker.offsetHeight}px`)
        el.style.setProperty('--marker-display', 'block')
        el.style.setProperty('--marker-transition-property', transitionProperty)
      } else el.style.setProperty('--marker-display', 'none')
    }
  }

  const { mobile, width } = useMediaInfo()

  const handleChange = (ev: InputChangeEvent) => {
    if (onChange != null) onChange(ev)

    // TODO: Work in progress getting tabs to scroll to top in property detail when tab clicked
    // Timeout to account for temporary content collapse when loading surrounding props while it loads
    if (mobile && type === 'tab') setTimeout(() => {
      const el = ref.current
      if (el != null) el.scrollIntoView()
    }, 100)
  }

  useEffect(() => {
    // Clear selection marker if there's no longer a selected item
    if (activeMarker != null && items.findIndex(i => i.value === value) < 0) setActiveMarker(null)
  }, [items, value])

  useEffect(updateMarkerPos, [activeMarker, items, width])

  return (
    <InputWrapper {...rest} wrapperType={wrapperType}>
      <div
        className={classNames(css.radioGroup, css[type], css[`layout-${layout ?? 'default'}`], entryClassName)}
        ref={entryRef}
      >
        <div className={css.items} ref={ref}>
          {items.map(item => (
            <RadioButton
              focus={focus === true && value === item.value}
              item={item}
              key={(typeof item.value === 'object' ? item.label : item.value) ?? ''}
              name={name}
              onChange={handleChange}
              onClick={onItemClick}
              selected={value === item.value}
              setActiveMarker={setActiveMarker}
              type={type}
              typeDef={typeDef}
              width={manualWidth ? `calc(100% / ${items.length})` : undefined}
            />
          ))}
        </div>
      </div>
    </InputWrapper>
  )
}

export default RadioGroup
