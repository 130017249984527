import { BrowserData, ClientRequest } from 'app/api/client/route'
import { DEV } from 'config'
import { useSearchParams } from 'next/navigation'
import { useEffect, useRef } from 'react'

const useInitScripts = () => {
  const params = useSearchParams()

  const attempts = useRef(0)

  useEffect(() => {
    if (params != null) {
      const searchParams: Partial<Record<string, string>> = {}
      params.forEach((value, key) => searchParams[key] = value)

      const browserData: BrowserData = {
        searchParams,
        ...(typeof document === 'undefined' ? {} : {
          href: document.location.href,
          referrer: document.referrer,
        })
      }

      const req: ClientRequest = {
        type: 'Page Load',
        browserData,
      }

      const invoke = () => {
        attempts.current++

        void fetch(`${DEV ? '' : 'https://app.propertyreach.com'}/api/client`, {
          method: 'POST',
          credentials: 'include',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify(req),
        }).catch(() => {
          if (attempts.current < 20) setTimeout(invoke, 5000)
        })
      }

      // Invoke immediately if query params are available
      if (Object.keys(searchParams).length > 0) invoke()
      else {
        // Invoke after a couple seconds if there appear to be no query params
        setTimeout(() => {
          if (attempts.current === 0) invoke()
        }, 2000)
      }
    }
  }, [params])
}

export default useInitScripts
