import React, { FC } from 'react'
import css from './labeled.module.scss'

const Labeled: FC<{
  children?: any
  label: string
  required?: boolean
}> = ({
  children,
  label,
  required= false
}) => (
  <div className={css.labeled}>
    <div className={css.label}>
      {label}
      {required && <span>*</span>}
    </div>
    {children}
  </div>
)

export default Labeled
