import classNames from 'classnames'
import React, { FC } from 'react'
import css from './svg.module.scss'

export type SvgId = 'find' | 'pull' | 'close' | 'logo' | 'logoLight' | 'logoSmall'

const Svg: FC<{
  className?: string
  fillPrimary?: boolean
  height?: number | string
  id: SvgId
  width?: number | string
}> = ({
  className,
  fillPrimary = false,
  height,
  id,
  width
}) => (
  <svg
    className={classNames(className, css.svg, { [css.fillPrimary]: fillPrimary })}
    style={{
      height: height == null ? undefined : height,
      width: width == null ? undefined : width,
    }}
  >
    <use xlinkHref={`#${id}`} />
  </svg>
)

export default Svg
