'use client'

import classNames from 'classnames'
import React, { FC } from 'react'
import { RotatingLines } from 'react-loader-spinner'

import css from './spinner.module.scss'

const Spinner: FC<{
  className?: string
  size?: number
}> = ({
  className,
  size = 96,
}) => {
  return (
    <span className={classNames(css.spinner, className)}>
      <RotatingLines strokeColor="currentColor" animationDuration="0.75" strokeWidth="3" width={String(size)} />
    </span>
  )
}

export default Spinner
