import classNames from 'classnames'
import Footer from 'components/footer'
import Link from 'components/Link'
import Menu from 'components/Menu'
import { QueryCache, QueryContext } from 'components/query'
import Svg from 'components/Svg'
import SvgSprite from 'components/Svg/SvgSprite'
import TrialButton from 'components/cta-button'
import useRouteChange from 'components/useRouteChange'
import { LOGIN_URL } from 'config'
import useInitScripts from 'hooks/useInitScripts'
import type { AppProps } from 'next/app'
import { useRouter } from 'next/router'
import React, { FC, MouseEvent, useRef, useState } from 'react'
import { Inter } from "next/font/google"

import css from './main.module.scss'
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.css'
import 'react-toggle/style.css'

const inter = Inter({ weight: ['300', '500', '600', '700', '800', '900'], subsets: ['latin']})

const Main: FC<AppProps> = ({ Component, pageProps }) => {
  const [cache, setCache] = useState<QueryCache>({})
  const { pathname } = useRouter()

  const home = pathname === '/'

  const [scrolling, setScrolling] = useState(false)

  const contentRef = useRef<HTMLDivElement>(null)

  const headerRef = useRef<HTMLDivElement>(null)

  const scrollToTop = () => contentRef.current?.scrollTo({ top: 0 })

  useRouteChange(undefined, scrollToTop)

  const handleScroll = () => setScrolling(contentRef.current != null && contentRef.current.scrollTop > 0)

  const handleClick = (ev: MouseEvent) => {
    // Using this until we can get the standard mobile scroll to top screen tap working. Body not being the scroll element
    // kills that; body is not the scroll element since sticky/fixed positioning for the header is wonky on mobile Safari.
    if (ev.target === headerRef.current) scrollToTop()
  }

  const links = [
    { href: '/search', label: 'Search' },
    { href: '/features', label: 'Features' },
    { href: '/pricing', label: 'Pricing' },
    { href: '/customers', label: 'Customers' },
    { href: '/about', label: 'About Us' },
    { href: '/contact', label: 'Contact' },
  ]

  useInitScripts()

  // Fixed header examples: stackoverflow
  return (
    <QueryContext.Provider value={{ cache, setCache }}>
      <div className={classNames(inter.className, css.main, { [css.scrolling]: scrolling, [css.home]: home })}>
        <div className={css.header}>
          <Link href="/pricing" className={css.alertBanner}>
            <div className={css.desktop}><span>EXCITING NEWS:</span> We now offer FREE skip tracing with all paid plans. <span>Learn More</span></div>
            <div className={css.mobile}>FREE skip tracing with all paid plans. <span>Learn More</span></div>
          </Link>
          <div className={css.inner} ref={headerRef} onClick={handleClick}>
            <Link ariaLabel="Home" href="/">
              <Svg fillPrimary  id="logo" className={css.logo} />
            </Link>
            <div className={css.right}>
              <div className={css.links}>
                {links.map(({ href, label }) => (
                  <Link
                    key={href}
                    href={href}
                    className={pathname.startsWith(href) ? css.selected : undefined}
                  >
                    {label}
                  </Link>
                ))}
                <Link href={LOGIN_URL} target="app">Log In</Link>
              </div>
              {!pathname.startsWith('/property-api') && <TrialButton type="solid" />}
            </div>
            <div className={css.menu}>
              <Menu
                items={[
                  { label: 'Home', href: '/' },
                  { label: 'Search', href: '/search' },
                  { label: 'Features', href: '/features' },
                  { label: 'Pricing', href: '/pricing' },
                  { label: 'Customers', href: '/customers' },
                  { label: 'About Us', href: '/about' },
                  { label: 'Contact', href: '/contact' },
                  { label: 'Log In', href: LOGIN_URL },
                ]}
              />
            </div>
          </div>
        </div>
        <div className={css.content} onScroll={handleScroll} ref={contentRef}>
          <SvgSprite />
          <Component {...pageProps} />
          <Footer />
        </div>
      </div>
    </QueryContext.Provider>
  )
}

export default Main
