import pluralize from 'pluralize'
import { MutableRefObject, useRef } from 'react'
import { toast } from 'react-toastify'
import { camelCaseToTitleCase } from 'utils/string'
import useFocusRef from './useFocusRef'

export interface ValidationResult {
  message: string | null
  passed: boolean
}

export interface Validation {
  allowBlankString?: boolean
  allowEmptyString?: boolean
  focus?: boolean
  label?: string
  name?: string
  required?: boolean
  requiredMessage?: string
  result?: ValidationResult
  validator: Validator
}

export interface ValidationContext {
  refs: ValidatorRefs
  validations: Validation[]
}

export type Validator = (validation: Validation, data: any) => ValidationResult
export type ValidatorRefs = Record<string, MutableRefObject<HTMLElement | undefined>>

export const passedValidator: Validator = () => ({ message: null, passed: true })

export const failedValidator: Validator = (validation) => ({ message: validation.requiredMessage ?? 'Data is invalid.', passed: false })

export const requiredValidator: Validator = (validation, data) => {
  const { allowBlankString = false, allowEmptyString = false, label, name, requiredMessage } = validation
  let message: string | null = null

  if (name != null) {
    let value = data[name]

    if (Array.isArray(value) && value.length === 0) value = null
    else if (typeof value === 'string') {
      if (!allowBlankString) value = value.replaceAll(/\s/g, '')
      if (!allowEmptyString && value === '') value = null
    }

    if (value == null) message = requiredMessage ?? `${label as string} is required.`
  }

  return { message, passed: message == null }
}

export const passwordValidator: Validator = (validation, data) => {
  const { name, required = false } = validation
  let message: string | null = null

  if (name != null) {
    const value = data[name]

    // Function will either return true or a message indicating what is invalid.
    if (value == null || typeof value !== 'string' || value.replaceAll(/\s/g, '') === '') message = required ? 'Password is required.' : null
    else if (value.length < 8) message = 'Password must be at least 8 characters.'
    else if (value.length > 20) message = 'Password cannot exceed 20 characters.'
    else if (/\s/.test(value)) message = 'Password cannot contain any white space.'
    else if (!/[A-Z]/.test(value)) message = 'Password must contain at least one capital letter.'
    else if (!/[^a-zA-Z0-9]/.test(value)) message = 'Password must contain at least one symbol.'
  }

  return { message, passed: message == null }
}

export const matchValidator: Validator = (validation, data) => {
  const { label, name } = validation
  let message: string | null = null

  if (name != null && data[name] !== data[`${name}Confirm`]) message = `${pluralize(label as string)} must match.`

  return { message, passed: message == null }
}

export const phoneValidator: Validator = (validation, data) => {
  const { label, name, required } = validation

  let message: string | null = null

  const value = (data[name ?? ''] ?? '').trim()

  if (value && !/^1?\d{10}$/.test(value.replace(/\D/g, ''))) message = `${label} must be a valid phone number.`
  else if (!value && required) message = `${label} is required.`

  return { message, passed: message == null }
}

export const emailValidator: Validator = (validation, data) => {
  const { label, name, required } = validation
  let message: string | null = null

  const value = (data[name ?? ''] ?? '').trim()

  if (value && !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value)) message = `${label} must be a valid email address.`
  else if (!value && required) message = `${label} is required.`

  return { message, passed: message == null }
}

export const zipValidator: Validator = (validation, data) => {
  const { label, name, required } = validation
  let message: string | null = null

  const value = (data[name ?? ''] ?? '').trim()

  if (value && !/^\d{5}$/.test(value)) message = `${label} must be a valid zip code.`
  else if (!value && required) message = `${label} is required.`

  return { message, passed: message == null }
}

export const trueValidator: Validator = (validation, data) => {
  const { label, name, requiredMessage } = validation
  let message: string | null = null

  if (name != null && data[name] !== true) message = requiredMessage ?? `${label} is required.`

  return { message, passed: message == null }
}

export const useValidation = (validations: Validation[]): ValidationContext => {
  const refs: ValidatorRefs = {}

  validations.forEach(validation => {
    const { focus = false, label, name } = validation
    if (name != null) {
      // Default label to name if not present
      if (label == null) validation.label = camelCaseToTitleCase(name)

      // Create ref for form field. Default it focused if indicated.
      // eslint-disable-next-line react-hooks/rules-of-hooks
      if (!(name in refs)) refs[name] = focus ? useFocusRef() : useRef<HTMLElement>()
    }
  })

  return {
    refs,
    validations
  }
}

export const getValidation = (name: string, validator: Validator, options: string | Partial<Validation> = {}): Validation => {
  return {
    ...(typeof options === 'string' ? { label: options } : options),
    name,
    validator
  }
}

export const optionalValue = (name: string, options?: string | Partial<Validation>): Validation => getValidation(name, passedValidator, options)

export const failedValue = (name: string, options?: string | Partial<Validation>): Validation => getValidation(name, failedValidator, options)

export const requiredValue = (name: string, options?: string | Partial<Validation>): Validation => getValidation(name, requiredValidator, options)

export const phoneValue = (name: string, options?: string | Partial<Validation>): Validation => getValidation(name, phoneValidator, options)

export const emailValue = (name: string, options?: string | Partial<Validation>): Validation => getValidation(name, emailValidator, options)

export const zipValue = (name: string, options?: string | Partial<Validation>): Validation => getValidation(name, zipValidator, options)

export const trueValue = (name: string, options?: string | Partial<Validation>): Validation => getValidation(name, trueValidator, options)

export const matchValue = (name: string, options?: string | Partial<Validation>): Validation => getValidation(name, matchValidator, options)

export const passwordValue = (name: string, options?: string | Partial<Validation>): Validation => getValidation(name, passwordValidator, options)

const validate = (context: ValidationContext, data: any): boolean => {
  let success = true
  context.validations.forEach((validation) => {
    const { passed, message } = validation.validator(validation, data)

    if (!passed && success) {
      const { name } = validation

      if (name != null) {
        if (message != null) toast(message, { autoClose: 3000 })

        const ref = context.refs[name]
        if (ref?.current != null) {
          const el = ref.current as HTMLInputElement
          if (el.select != null) el.select()
          else el.focus()
        }
      }

      success = false
    }
  })

  return success
}

// TODO: Replace all validate() instances with the following, and rename
export const validate2 = (context: ValidationContext, data: any): { success: boolean, message?: string } => {
  const result: { success: boolean, message?: string } = {
    success: true,
  }

  context.validations.forEach((validation) => {
    const { passed, message } = validation.validator(validation, data)

    if (!passed && result.success) {
      const { name } = validation

      if (name != null) {
        if (message) result.message = message

        const ref = context.refs[name]
        if (ref?.current != null) {
          const el = ref.current as HTMLInputElement
          if (el.select != null) el.select()
          else el.focus()
        }
      }

      result.success = false
    }
  })

  return result
}

export default validate
