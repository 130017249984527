'use client'

import classNames from 'classnames'
import { createInputChangeEvent } from 'components/input'
import InputWrapper from 'components/input/input-wrapper'
import { TextInputProps } from 'components/input/types'
import { textInputDefs, useInputValue } from 'components/input/utils'
import React, { ChangeEvent, FC, FocusEvent, KeyboardEvent, ReactNode, useEffect, useRef, useState } from 'react'
import { RotatingLines } from 'react-loader-spinner'
import { NumericFormat } from 'react-number-format'
import { debounce, setTimer } from 'utils/dom'
import css from './text-input.module.scss'

const TextField: FC<TextInputProps> = ({
  changeDelay = 0,
  children,
  clearIcon = false,
  constrainValue,
  def,
  entryClassName,
  disabled = false,
  disableArrows = false,
  focus = false,
  icon: userIcon,
  iconHref,
  iconPlacement = 'right',
  loading = false,
  maxLength: fieldMaxLength,
  name,
  onApply,
  onBlur,
  onChange,
  onContextChange,
  onFocus,
  onIconClick,
  onKeyDown,
  onReset,
  placeholder,
  prefix,
  readOnly = false,
  style,
  textArea = false,
  type = 'string',
  value: inputValue,
  ...rest
}) => {
  const {
    allowNegative = false,
    decimalScale = 0,
    fieldType,
    fixedDecimalScale = false,
    converter: { formatter, parser },
    maxLength: typeMaxLength,
    thousandSeparator = false,
  } = def ?? textInputDefs[type]
  const [focused, setFocused] = useState(false)
  const { value } = useInputValue(inputValue, name)

  // Current textual representation of value displayed in the control. If control is focused it'll represent
  // the string being typed, otherwise the formatted input value.
  const [textValue, setTextValue] = useState<string>()
  let effectiveValue = textValue ?? ''

  // Actual up-to-date value of what's typed in. May not have been sent to caller yet if changeDelay > 0
  const currentValue = useRef(value)

  useEffect(() => {
    // Keep currentValue up-to-date with latest passed in from caller
    currentValue.current = value

    // Perform initial assignment of textValue. Apply new formatted value any time control does not have focus,
    // so as to not overwrite what the user is currently typing. Also immediately apply if in read-only mode, since
    // input will not be directly user-driven.
    if (readOnly || !focused || textValue === undefined) updateValue(formatter(value))

    if (onContextChange != null) {
      onContextChange({
        ...rest,
        focused,
        name,
        // Allow for asynchronous manual setting of the input value; validate both the textual and typed representations.
        onChange: value => updateValue(formatter(value), value, 0),
        value: currentValue.current,
      })
    }
  }, [focused, formatter, value, readOnly, onContextChange])

  // Timer/ref of latest value typed in. We delay updating caller if requested
  const timer = useRef<number>()
  const triggerChange = () => {
    if (timer.current !== undefined) {
      clearTimeout(timer.current)
      timer.current = undefined
    }

    if (onChange != null) onChange(createInputChangeEvent(name, currentValue.current))
  }

  const updateValue = (textValue: string, newValue?: any, delay = changeDelay) => {
    setTextValue(textValue)

    // Update the caller on new, valid value. If the value can't be parsed per the data type then do not update the
    // caller with an undefined value unless the field has been cleared. (i.e. as the user types we want the control's
    // effective value to remain the last valid value typed in.) Set value regardless if field is being cleared and
    // current value is not empty.
    if (newValue !== undefined || (textValue.trim() === '' && (value ?? '') !== '')) {
      currentValue.current = newValue
      if (delay > 0) setTimer(triggerChange, delay, timer)
      else triggerChange()
    }
  }

  // Update state with value being typed in, and potentially update caller with new parsed value.
  const handleChange = ({ target: { value }}: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => updateValue(value, parser(value))

  const ref = useRef<HTMLInputElement | null>(null)

  const setFocus = () => {
    const el = ref.current

    if (el != null) {
      el.select()
      el.scrollIntoView({ block: 'center' })
    }
  }

  useEffect(() => {
    if (focus) debounce(setFocus) // TODO: Async only seems necessary in popups
  }, [focus])

  const handleApply = () => {
    if (constrainValue != null) {
      // Trigger a change if there's an apply constraint; i.e. a constraint/formatter that should only happen if the field is tabbed out of, or Enter pressed.
      const val = constrainValue(value)
      if (val !== value) updateValue(formatter(val), val, 0)
    } else if (value !== currentValue.current) triggerChange() // Trigger immediate change if change notification is on a delay

    if (onApply != null) onApply()
  }

  const handleBlur = (ev: FocusEvent) => {
    setFocused(false)
    handleApply()

    if (onBlur != null) onBlur(ev)
  }

  const handleFocus = (ev: FocusEvent) => {
    setFocused(true)

    if (onFocus != null) onFocus(ev)
  }

  const handleIconClick = () => {
    setFocus()

    if (onIconClick != null) onIconClick()
    if (clearIcon && effectiveValue !== '') updateValue('')
  }

  const handleKeyDown = (ev: KeyboardEvent) => {
    const { key } = ev
    if (key === 'Enter') handleApply()
    else if (key === 'Escape' && onReset != null) onReset()
    else if (['ArrowUp', 'ArrowDown'].includes(key) && fieldType === 'number' && typeof currentValue.current === 'number') {
      const newValue = String(currentValue.current + (key === 'ArrowUp' ? 1 : -1))
      updateValue(newValue, parser(newValue))
    }

    if (onKeyDown != null) onKeyDown(ev)
  }

  const numeric = fieldType === 'number'

  const props = {
    ...rest,
    autoComplete: 'off',
    disabled,
    maxLength: fieldMaxLength ?? typeMaxLength,
    name,
    onBlur: handleBlur,
    onChange: handleChange,
    onFocus: handleFocus,
    onKeyDown: handleKeyDown,
    placeholder: Array.isArray(placeholder) ? placeholder[0] : placeholder,
    readOnly: readOnly,
    value: effectiveValue,
  }

  const preset = clearIcon && effectiveValue !== '' ? 'clear' : userIcon
  // const icon = preset == null || (loading && iconPlacement === 'right') ? undefined : (
  //   <Icon
  //     href={iconHref}
  //     onClick={handleIconClick}
  //     preset={preset}
  //     tabIndex={1000}
  //   />
  // )

  const icon: ReactNode = null

  return (
    <div className={classNames(css.textInput, { [css.focused]: focused, [css.disableArrows]: disableArrows }, entryClassName)} style={style}>
      {iconPlacement === 'left' && icon}
      {prefix !== undefined && <div className={css.prefix}>{prefix}</div>}
      {numeric && <NumericFormat {...props} fixedDecimalScale={fixedDecimalScale} thousandSeparator={thousandSeparator} decimalScale={decimalScale} allowNegative={allowNegative} getInputRef={ref} />}
      {!numeric && textArea && <textarea {...props} />}
      {!numeric && !textArea && <input {...props} ref={ref} type={fieldType === 'password' ? 'password' : 'text'} />}
      {children}
      {loading && <RotatingLines animationDuration="0.8" strokeColor="gray" strokeWidth="3" width="24" />}
      {iconPlacement === 'right' && icon}
    </div>
  )
}

const TextInput: FC<TextInputProps> = ({
  changeDelay,
  children,
  clearIcon,
  constrainValue,
  def,
  disabled,
  disableArrows,
  entryClassName,
  focus,
  icon,
  iconHref,
  iconPlacement,
  loading,
  max,
  maxLength,
  min,
  name,
  onApply,
  onBlur,
  onChange,
  onContextChange,
  onFocus,
  onIconClick,
  onKeyDown,
  onReset,
  placeholder,
  prefix,
  range = false,
  readOnly,
  style,
  textArea = false,
  type,
  value,
  wrapperType = 'border',
  ...rest
}) => {
  const inputs: TextInputProps[] = []
  const baseProps = {
    clearIcon, constrainValue, def, disabled, disableArrows, icon, iconHref, iconPlacement, loading, max, maxLength, min, name, onApply,
    onBlur, onChange, onContextChange, onFocus, onIconClick, onKeyDown, onReset, placeholder, prefix, readOnly, changeDelay,
    style, textArea, type, value
  }

  if (range) {
    const placeholders = Array.isArray(placeholder) ? placeholder : [placeholder]
    inputs.push({ ...baseProps, name: `${name}Min`, placeholder: placeholders[0] ?? (type === 'date' ? 'From' : 'No Min'), children, entryClassName, focus })
    inputs.push({ ...baseProps, name: `${name}Max`, placeholder: placeholders[1] ?? (type === 'date' ? 'To' : 'No Max') })
  } else inputs.push({ ...baseProps, name: name ?? 'input', children, entryClassName, focus })

  return (
    <InputWrapper {...rest} focus={focus} wrapperType={wrapperType}>
      {inputs.map((props) => <TextField {...props} key={props.name} />)}
    </InputWrapper>
  )
}

export default TextInput
