import dayjs from 'dayjs'

export interface FormatOptions {
  excludePrefix?: boolean
  format?: string
  forceDecimal?: boolean
  maximumFractionDigits?: number
  minimumFractionDigits?: number
  prefix?: string
  round?: boolean
  suffix?: string
  toLocaleStringOptions?: object
}

export type Formatter = (value: any, options?: FormatOptions) => string

// export const formatDefault = (val: any, options: FormatOptions): string => val as string

export const formatDate = (date: number | Date | dayjs.Dayjs | string | null | undefined, options: FormatOptions = {}): string => {
  if (date == null) return ''

  const dt = dayjs(typeof date === 'number' && date < 10000000000 ? date * 1000 : date)
  if (!dt.isValid()) return ''

  const {
    format = 'MM/DD/YYYY'
  } = options

  return dt.format(format)
}

export const formatNumber = (number: any, options: FormatOptions = {}): string => {
  let num = Number(number)
  if (number == null || isNaN(num)) return ''

  const {
    forceDecimal = false,
    maximumFractionDigits,
    minimumFractionDigits,
    prefix = '',
    round = false,
    suffix = '',
    toLocaleStringOptions = {}
  } = options

  if (round) num = Math.round(num)

  let numFormatted = num.toLocaleString('en-US', {
    maximumFractionDigits,
    minimumFractionDigits,
    style: 'decimal',
    ...toLocaleStringOptions
  })

  if (prefix !== '') numFormatted = `${prefix}${numFormatted}`
  if (suffix !== '') numFormatted = `${numFormatted}${suffix}`

  if (!forceDecimal) numFormatted = numFormatted.replace(/\.00$/, '')

  return numFormatted
}

export const formatCurrency = (amount: any, options: FormatOptions = {}): string => {
  const {
    minimumFractionDigits = 2,
    maximumFractionDigits = 2,
    excludePrefix = false,
    prefix
  } = options

  return formatNumber(amount, { ...options, minimumFractionDigits, maximumFractionDigits, prefix: prefix ?? (excludePrefix ? '' : '$') })
}

export const formatCurrencyFull = (amount: any, options: FormatOptions): string => {
  const val = formatCurrency(amount, options)
  return val != null && !val.includes('.') ? `${val}.00` : val
}

export const formatPercent = (amount: any, options: FormatOptions = {}): string => {
  const {
    maximumFractionDigits = 2
  } = options

  return formatNumber(amount == null ? null : amount * 100, { ...options, maximumFractionDigits, suffix: '%' })
}

export const formatYesNo = (val: any): string => {
  if (val == null || val === '') return ''
  if (val === false || val === 0 || (typeof val === 'string' && ['N', 'NO', 'FALSE'].includes(val))) return 'No'

  return 'Yes'
}

export const formatSquareFeet = (number: any, options: FormatOptions = {}): string => {
  const num = formatNumber(number, options)

  return num === '' ? '' : `${num} Sq.Ft.`
}

export const formatYearsMonths = (months: any): string => {
  let num = Number(months)
  if (months == null || isNaN(num)) return ''

  const mo = num % 12
  const yr = Math.floor(num / 12)

  return `${yr === 0 ? '' : `${yr}yr`} ${mo === 0 ? '' : `${mo}mo`}`.trim()
}
