import { useEffect } from 'react'

const useWindowResize = (handleResize: (ev: Event) => void): void => {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  });
}

export default useWindowResize
