import classNames from 'classnames'
import Link from 'components/Link'
import React, { FC, MouseEvent, useEffect, useState } from 'react'
import { BsChevronDown } from 'react-icons/bs'
import { HiOutlineMenuAlt2 } from 'react-icons/hi'
import { IoCloseSharp } from 'react-icons/io5'
import { formatNumber } from 'utils/format'
import useScrollMenu from 'components/Menu/useScrollMenu'

import css from './menu.module.scss'

export type MenuType = 'slide' | 'sticky-slide'

export interface MenuItem {
  quantity?: any[] | number | string
  href?: string
  key?: number | string
  label: string
  menuLabel?: string
}

export const MenuDropButton: FC<{ openMenu: () => void }> = ({ openMenu }) => (
  <div className={css.dropButton} onClick={openMenu}>
    <BsChevronDown />
  </div>
)

const Menu: FC<{
  activeKey?: number | string
  headerContent?: JSX.Element
  headerClassName?: string
  items: MenuItem[]
  onClose?: () => void
  onSelect?: (key: number | string) => void
  open?: boolean
  type?: MenuType
}> = ({
  activeKey,
  headerContent,
  headerClassName,
  items,
  onClose,
  onSelect,
  open: manualOpen,
  type = 'slide'
}) => {
  const [open, setOpen] = useState(false)
  const effectiveOpen = manualOpen ?? open

  const [opened, setOpened] = useState(effectiveOpen)
  const [visible, setVisible] = useState(effectiveOpen)

  useEffect(() => {
    if (effectiveOpen) {
      setOpened(true)
      setTimeout(() => setVisible(true), 1)
    } else {
      setVisible(false)
      setTimeout(() => setOpened(false), 300)
    }
  }, [effectiveOpen])

  const handleOpen = (open: boolean, ev?: MouseEvent) => {
    setOpen(open)

    if (!open && onClose != null) onClose()
  }

  const handleSelect = (key: string | number) => {
    if (onSelect != null) onSelect(key)
    handleClose()
  }

  const handleClose = () => handleOpen(false)

  return (
    <div className={classNames(css.menu, css[type], { [css.opened]: opened, [css.visible]: visible })}>
      <div className={css.button}>
        <HiOutlineMenuAlt2 onClick={() => handleOpen(!effectiveOpen)} size={24} />
      </div>
      <div className={css.container} onClick={ev => handleOpen(false)}>
        <div className={css.body} onClick={ev => ev.stopPropagation()}>
          <div className={css.closeButton} onClick={() => handleOpen(false)}>
            <IoCloseSharp size={30} />
          </div>
          {headerContent != null && <div className={classNames(css.header, headerClassName)}>{headerContent}</div>}
          {items.map(item => {
            const { quantity, href, label, menuLabel, key } = item
            const effectiveKey = key ?? href ?? label

            // Show quantity bubble if the effective value doesn't evaluate to zero
            const effectiveQuantity = (Array.isArray(quantity) ? quantity.length : quantity) ?? 0

            const content = (
              <div
                className={classNames(css.item, { [css.active]: activeKey != null && activeKey === key })}
                key={effectiveKey}
                onClick={key == null ? undefined : () => handleSelect(key)}
              >
                {menuLabel ?? label}
                {typeof effectiveQuantity === 'number' && effectiveQuantity > 0 && <div className={css.quantity}>{formatNumber(effectiveQuantity)}</div>}
              </div>
            )

            return href == null ? content : <Link href={href} key={effectiveKey} onClick={handleClose}>{content}</Link>
          })}
        </div>
      </div>
    </div>
  )
}

export { useScrollMenu }

export default Menu
