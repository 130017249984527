import useWindowResize from 'hooks/useWindowResize'
import { useState } from 'react'

export interface MediaInfo {
  height: number
  mobile: boolean
  width: number
}

export interface MediaInfoOptions {
  onWindowResize?: (ev: Event) => void
  mobileWidth?: number
}

const useMediaInfo = (options: MediaInfoOptions = {}): MediaInfo => {
  const {
    mobileWidth = 600,
    onWindowResize,
  } = options

  const getMediaInfo = (): MediaInfo => {
    if (typeof window === 'undefined') return { height: 0, width: 0, mobile: false }

    return {
      height: window.innerHeight,
      width: window.innerWidth,
      mobile: window.innerWidth <= mobileWidth,
    }
  }

  const [info, setInfo] = useState(getMediaInfo)

  useWindowResize(ev => {
    setInfo(getMediaInfo())

    if (onWindowResize !== undefined) onWindowResize(ev)
  })

  return info
}

export default useMediaInfo
