import MetaInfo from 'components/MetaInfo'
import type { AppProps } from 'next/app'
import Main from 'components/Main'
import { useRouter } from 'next/router'
import React, { FC } from 'react'
import Modal from 'react-modal'
import { ToastContainer } from 'react-toastify'

import '../styles/globals.scss'
import 'react-toastify/dist/ReactToastify.css'
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toggle/style.css'

Modal.setAppElement('#__next')

const App: FC<AppProps> = (props) => {
  const { asPath } = useRouter()

  return (
    <>
      <MetaInfo title="Unlock Real Estate Investment Opportunities at PropertyReach" suppressCompany>
        <link rel="shortcut icon" href="/logo-sm.png" />
        <link rel="canonical" href={`https://www.propertyreach.com${asPath}`} />
        <meta name="robots" content="follow, index, max-snippet:-1, max-video-preview:-1, max-image-preview:large"/>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="format-detection" content="telephone=no" />
      </MetaInfo>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{ zIndex: 100000 }}
      />
      <Main {...props} />
    </>
  )
}

export default App
