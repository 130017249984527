import { useEffect } from 'react'

const useWindowScroll = (handleScroll: (ev: Event) => void): void => {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', handleScroll);

      return () => {
        window.removeEventListener('scroll', handleScroll);
      }
    }
  })
}

export default useWindowScroll
