'use client'

import classNames from 'classnames'
import useWindowScroll from 'hooks/useScroll'
import React, {
  createContext,
  CSSProperties,
  FC,
  RefObject,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react'
import { IoIosHelpCircleOutline } from 'react-icons/io'
import { stopHandler } from 'utils/dom'
import css from './help.module.scss'

type HelpInfo = {
  content: string | React.ReactNode,
  ref: RefObject<HTMLDivElement>
}

type HelpPosition = 'inline' | 'top-left' | 'top-right' | 'bottom-right' | 'bottom-left'

type HelpTrigger = (info?: HelpInfo) => void

const HelpContext = createContext<HelpTrigger | null>(null)

const isNear = (clientX: number, clientY: number, ref: RefObject<HTMLDivElement>) => {
  if (ref.current == null) return false

  const buf = 5
  const { height, x, y, width } = ref.current.getBoundingClientRect()
  return !(clientX < x - buf || clientX > x + width + buf || clientY < y - buf || clientY > y + height + buf)
}

export const HelpProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
  const [info, setInfo] = useState<HelpInfo | undefined>()
  const [loadInfo, setLoadInfo] = useState<HelpInfo | undefined>()
  const [opacity, setOpacity] = useState(0)
  const [style, setStyle] = useState<CSSProperties>({ left: 0, top: 0 })
  const measureRef = useRef<HTMLDivElement | null>(null)
  const bubbleRef = useRef<HTMLDivElement | null>(null)

  const handleHelp = (info?: HelpInfo) => {
    setLoadInfo(info)
  }

  const timerRef = useRef<number | null>(null)

  useEffect(() => {
    if (timerRef.current != null) {
      window.clearTimeout(timerRef.current)
      timerRef.current = null
    }

    if (loadInfo !== undefined) {
      timerRef.current = window.setTimeout(() => {
        const measureEl = measureRef.current
        const bubbleEl = bubbleRef.current
        if (loadInfo?.ref.current != null && measureEl != null && bubbleEl != null) {
          const { height, width } = measureEl.getBoundingClientRect()
          const { height: h, width: w, x, y } = loadInfo.ref.current.getBoundingClientRect()
          const half = Math.round(width / 2)
          const left = x - half + Math.round(w / 2)

          // Determine horizontal offset if content would extend off the left or right window edge
          const offset = Math.max(Math.min(0, left), (left + width) - window.innerWidth)

          bubbleEl.style.setProperty('--arrow-left', `${half + offset - 4}px`)
          bubbleEl.style.setProperty('--arrow-top', `${height - 4}px`)

          setLoadInfo(undefined)
          setInfo(loadInfo)
          setOpacity(1)
          setStyle({
            left: left - offset,
            top: y - height - 2,
            width
          })

          if (y + h + 2 + height < window.innerHeight) {
            bubbleRef.current?.style.setProperty('--arrow-top', `${-4}px`)
            setStyle({
              left: left - offset,
              top: y + h + 2,
              width
            })
          }
        }
      }, 300)
    }
  }, [loadInfo])

  useEffect(() => {
    const handleMouseMove = (ev: MouseEvent) => {
      // Hide bubble (set info = null) if mouse has moved away from it
      const { clientX, clientY } = ev
      if (info === undefined || (!isNear(clientX, clientY, bubbleRef) && !isNear(clientX, clientY, info.ref))) setOpacity(0)
    }

    window.addEventListener('mousemove', handleMouseMove)

    return () => {
      window.removeEventListener('mousemove', handleMouseMove)
    }
  })

  // Hide on window scroll
  useWindowScroll(() => setOpacity(0))

  const maxWidth = typeof loadInfo?.content === 'string' ? 300 : undefined

  return (
    <HelpContext.Provider value={handleHelp}>
      <div className={css.measureBubble} ref={measureRef} style={{ maxWidth }}>{loadInfo?.content}</div>
      <div
        className={css.helpBubble}
        ref={bubbleRef}
        style={{
          ...style,
          opacity,
          maxWidth,
          pointerEvents: opacity === 0 ? 'none' : 'all',
        }}
      >{info?.content}</div>
      {children}
    </HelpContext.Provider>
  )
}

const Help: FC<{
  children: React.ReactNode
  className?: string
  position?: HelpPosition
  size?: number
}> = ({
  children,
  className,
  position = 'inline',
  size = 15
}) => {
  const ref = useRef<HTMLDivElement | null>(null)
  const handleHelp = useContext(HelpContext)

  return handleHelp == null ? null : (
    <span
      className={classNames(css.help, css[position], className)}
      onMouseEnter={() => handleHelp != null && handleHelp({ content: children, ref })}
      onMouseLeave={() => handleHelp != null && handleHelp()}
      onClick={stopHandler}
      ref={ref}
    >
      <IoIosHelpCircleOutline size={size} />
    </span>
  )
}

export default Help
