'use client'

import classNames from 'classnames'
import Link from 'components/Link'
import Svg from 'components/Svg'
import Image from 'next/image'
import React, { FC, ReactNode } from 'react'
import { IconType } from 'react-icons'
import {
  AiFillFacebook,
  AiFillLinkedin,
  AiOutlineFacebook, AiOutlineFilePdf,
  AiOutlineInstagram,
  AiOutlineLinkedin,
  AiOutlineMail, AiOutlinePlus
} from 'react-icons/ai'
import { BiCheck, BiChevronDown } from 'react-icons/bi'
import { BsGear, BsSend, BsTrash3 } from 'react-icons/bs'
import { FiEdit } from 'react-icons/fi'
import { HiMenu, HiMenuAlt2, HiOutlineCalendar, HiOutlinePhone } from 'react-icons/hi'
import { ImHome, ImPhone } from 'react-icons/im'
import { IoIosMail, IoMdCloseCircle } from 'react-icons/io'
import {
  IoAddCircleOutline,
  IoChevronBackCircle,
  IoChevronForwardCircle,
  IoClose,
  IoInfiniteSharp,
  IoSearchOutline
} from 'react-icons/io5'
import { MdCheckCircle, MdCircle } from 'react-icons/md'
import { RiTwitterFill, RiTwitterLine } from 'react-icons/ri'
import { VscCallOutgoing } from 'react-icons/vsc'

import instagram from 'public/images/instagram.svg'
import css from './icon.module.scss'

export type IconPreset =
  'add' | 'available' | 'calendar' | 'call' | 'caret' | 'circle' | 'clear' | 'close' | 'complete' | 'confirmed' | 'edit' | 'email' | 'email-solid' | 'facebook' |
  'facebook-open' | 'gear' | 'home' | 'infinity' | 'info' | 'instagram' | 'instagram-open' | 'linked-in' | 'linked-in-open' | 'menu' | 'menu2' | 'pdf' |
  'phone' | 'phone-solid' | 'plus' | 'search' | 'send' | 'threads' | 'threads-open' | 'toggle-forward' | 'toggle-backward' | 'trash' | 'twitter' | 'twitter-open' | 'unavailable'

export type IconPresetDef = {
  size: number,
  Icon?: IconType,
  image?: ReactNode,
  text?: string,
}

const iconPresets: Record<IconPreset, IconPresetDef> = {
  'add': { Icon: IoAddCircleOutline, size: 22 },
  'available': { Icon: BiCheck, size: 26 },
  'call': { Icon: VscCallOutgoing, size: 20 },
  'caret': { Icon: BiChevronDown, size: 26 },
  'clear': { Icon: IoMdCloseCircle, size: 26 },
  'close': { Icon: IoMdCloseCircle, size: 26 },
  'calendar': { Icon: HiOutlineCalendar, size: 20 },
  'circle': { Icon: MdCircle, size: 20 },
  'confirmed': { Icon: MdCheckCircle, size: 18 },
  'complete': { Icon: BiCheck, size: 28 },
  'edit': { Icon: FiEdit, size: 18 },
  'email': { Icon: AiOutlineMail, size: 18 },
  'email-solid': { Icon: IoIosMail, size: 20 },
  'facebook': { Icon: AiOutlineFacebook, size: 20 },
  'facebook-open': { Icon: AiFillFacebook, size: 20 },
  'gear': { Icon: BsGear, size: 24 },
  'home': { Icon: ImHome, size: 18 },
  'infinity': { Icon: IoInfiniteSharp, size: 24 },
  'info': { size: 20, text: 'i' },
  'instagram': { Icon: AiOutlineInstagram, size: 20 },
  'instagram-open': { size: 20, image: <Image src={instagram} alt="Instagram" height={20} width={20} unoptimized />},
  'linked-in': { Icon: AiOutlineLinkedin, size: 20 },
  'linked-in-open': { Icon: AiFillLinkedin, size: 20 },
  'menu': { Icon: HiMenuAlt2, size: 26 },
  'menu2': { Icon: HiMenu, size: 26 },
  'pdf': { Icon: AiOutlineFilePdf, size: 20 },
  'phone': { Icon: HiOutlinePhone, size: 20 },
  'phone-solid': { Icon: ImPhone, size: 18 },
  'plus': { Icon: AiOutlinePlus, size: 24 },
  'search': { Icon: IoSearchOutline, size: 26 },
  'send': { Icon: BsSend, size: 18 },
  'toggle-forward': { Icon: IoChevronForwardCircle, size: 24 },
  'toggle-backward': { Icon: IoChevronBackCircle, size: 24 },
  'threads': { size: 20, image: <Svg id="close" height={18} width={18} />}, // threads
  'threads-open': { size: 20, image: <Svg id="close" height={18} width={18} />}, // threads
  'trash': { Icon: BsTrash3, size: 18 },
  'twitter': { Icon: RiTwitterLine, size: 20 },
  'twitter-open': { Icon: RiTwitterFill, size: 20 },
  'unavailable': { Icon: IoClose, size: 26 },
}

/**
 * Div wrapper for an icon element.
 */
const Icon: FC<{
  active?: boolean
  children?: ReactNode,
  className?: string
  href?: string | undefined
  preset?: IconPreset | undefined
  Icon?: IconType
  onClick?: (ev: React.MouseEvent) => void
  size?: number
  tabIndex?: number
}> = ({
  active = false,
  children,
  className,
  href,
  preset: presetType,
  Icon,
  onClick,
  size,
  tabIndex,
}) => {
  const preset = presetType == null ? undefined : iconPresets[presetType]
  let content: any = preset?.image
  const text = preset?.text
  const UseIcon = Icon ?? preset?.Icon

  if (content == null && UseIcon != null) content = <UseIcon size={size ?? preset?.size ?? 20} />
  if (content == null && text != null) content = text

  return content == null ? null : (
    <div
      className={classNames(
        css.icon,
        {
          [css.clickable]: onClick != null,
          [css.active]: active,
        },
        presetType === undefined ? undefined : css[`preset-${presetType}`],
        className
      )}
      onClick={onClick}
    >
      <div className={css.graphic}>
        {href == null ? content : <Link href={href} tabIndex={tabIndex}>{content}</Link>}
      </div>
      {children}
    </div>
  )
}

export default Icon
