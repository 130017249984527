'use client'

import classNames from 'classnames'
import { CalendarInputType, CalendarProps } from 'components/input/types'
import dayjs, { Dayjs } from 'dayjs'
import { FC, useEffect, useMemo, useState } from 'react'
import { IoChevronBack, IoChevronForward } from 'react-icons/io5'
import { isDayjsSameOrAfter, isDayjsSameOrBefore } from 'utils/date'
import css from './calendar.module.scss'

const days = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa']

const getCalendarInput = (value: CalendarInputType): Dayjs | undefined => value === undefined ? undefined : dayjs(value).startOf('day')

const today = () => dayjs().startOf('day')

const Calendar: FC<CalendarProps> = ({
  className,
  initialDate = dayjs(),
  onChange,
  style,
  value,
}) => {
  const [activeDate, setActiveDate] = useState(dayjs(getCalendarInput(initialDate) ?? today()))

  const { monthDateMin, monthDateMax, visibleDateMin, visibleDateMax } = useMemo(() => {
    const monthDateMin = dayjs(activeDate).startOf('month')
    const monthDateMax = dayjs(activeDate).endOf('month')

    return {
      monthDateMin,
      monthDateMax,
      visibleDateMin: dayjs(monthDateMin).startOf('week'),
      visibleDateMax: dayjs(monthDateMax).endOf('week').subtract(1, 'day'),
    }
  }, [activeDate])

  const dates = [dayjs(visibleDateMin)]
  while (isDayjsSameOrBefore(dates[dates.length - 1], visibleDateMax)) dates.push(dayjs(dates[dates.length - 1]).add(1, 'day'))

  // Get Moment representation of current (possibly undefined) date value being passed in
  const valueInput = useMemo(() => getCalendarInput(value), [value])

  // Updated the active / highlighted date. This determines the current month being displayed and may not reflect the
  // current date value's month.
  useEffect(() => {
    if (valueInput !== undefined) setActiveDate(valueInput)
  }, [valueInput])

  const selectDate = (date?: Dayjs) => {
    if (onChange != null) onChange(date === undefined ? undefined : date.format('YYYY-MM-DD'))
  }

  return (
    <div
      className={classNames(css.calendar, className)}
      onMouseDown={(ev) => ev.preventDefault()} // Prevent text input from blurring
      style={style}
    >
      <div className={css.controls}>
        <div className={css.button}>
          <IoChevronBack size={17} onClick={() => setActiveDate(dayjs(activeDate.subtract(1, 'month')))} />
        </div>
        <div>{activeDate.format('MMMM YYYY')}</div>
        <div className={css.button}>
          <IoChevronForward size={17} onClick={() => setActiveDate(dayjs(activeDate.add(1, 'month')))} />
        </div>
      </div>
      <div className={css.days}>
        {days.map(d => <div className={css.day} key={d}>{d}</div>)}
        {dates.map(d => (
          <div
            className={classNames(css.day, css.date, {
              [css.active]: d.isSame(activeDate),
              [css.currentMonth]: isDayjsSameOrAfter(d, monthDateMin) && isDayjsSameOrBefore(d, monthDateMax),
              [css.selected]: d.isSame(valueInput),
            })}
            key={d.valueOf()}
            onClick={() => selectDate(d)}
          >
            {d.date()}
          </div>
        ))}
      </div>
    </div>
  )
}

export default Calendar
